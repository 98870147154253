import { makeStyles } from '@energi/ui';

export default makeStyles(theme => ({
	root: {
		position: 'fixed',
		bottom: theme.spacing(4),
		right: theme.spacing(3),
	},
	wifion: {
		position: 'relative',
		marginLeft: -5,
		marginRight: 12,
		top: 2,
	},
	wifioff: {
		position: 'relative',
		marginLeft: -5,
		marginRight: 12,
		top: 2,
	},
	alert: {
		width: 'auto',
		fontSize: 16,
		fontWeight: 600,
		padding: theme.spacing(1.75, 2.65),
		borderRadius: 6,
		color: theme.palette.grey[1000],
		backgroundColor: theme.palette.common.white,
		marginRight: 24,
	},
	icon: {
		transform: 'scale(1.4)',
		marginLeft: 18,
		marginRight: 12,
	},
}));

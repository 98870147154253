import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
	Container,
	Grid,
	Box,
	Link,
	useTheme,
	useMediaQuery,
} from '@energi/ui';
import { toBN, subscribeToWeb3Socket } from '@energi/utils';
import { useMetamask } from '@energi/energi-wallet';
import networkCtx from 'context/network';
import BridgeHistory from 'components/bridge/BridgeHistory';
import SwapAsset from 'components/bridge/SwapAsset';
import ConvertToken from 'components/bridge/ConvertToken';
import LinkWithRef from 'components/common/LinkWithRef';
import ChooseWallet from 'components/bridge/ChooseWallet';
import TabsMenu from 'components/common/TabsMenu';
import LeftSidebar from 'components/common/LeftSidebar';
import OfflineModal from 'components/common/OfflineModal';
import TransactionErrorModal from 'components/common/TransactionErrorModal';
import WarningModal from 'components/common/WarningModal';
import TransactionInfoModal from 'components/common/TransactionInfoModal';
import TypeformModal from 'components/common/TypeformModal';
import NotifyTransactionModal from 'components/common/NotifyTransactionModal';
import errorCtx from 'context/error';
import tokenListCtx from 'context/tokenlist';
import modalsCtx from 'context/modals';
import chainsCtx from 'context/chains';
import EnergiLogo from 'assets/images/logo.svg';
import latestBlockCtx from 'context/latestBlock';
import useActivityChecker from 'hooks/useActivityChecker';
import LowBalanceBanner from 'components/common/LowBalanceBanner';
import WalletInfo from 'components/common/WalletInfo';
import NotMetamaskInstalledModal from 'components/common/NotMetamaskInstalledModal';
import BookmarkModal from 'components/common/BookmarkModal';
import ConnectToMetamask from 'components/common/ConnectToMetamask';
import { getDepositFee } from 'utils/smartcontracts/tokencashier';
import useStyles from 'styles/components/layout/BridgePage';
import {
	VALID_CHAINS,
	ALL_CHAINS,
	ENV_ENERGI_CHAINID,
} from 'utils/constants/chains';
import { useIntl } from 'utils/helper';

const getMeta = (children, key, def) => {
	const val = children?.type?.meta && children?.type?.meta[key];
	return val === undefined ? def : val;
};

const BridgePage = ({ children, ...props }) => {
	const t = useIntl();
	const localStorageBridge = localStorage.getItem('bridgePage');

	const TRANSACTION_ERROR_MESSAGES = {
		'-32603': t('snackbar_notification.low_gas'),
	};

	const isIdle = useActivityChecker();
	const headerVariant = getMeta(children, 'headerVariant', 'default');
	const classes = useStyles({ headerVariant });
	const theme = useTheme();
	const smallScreenSize = useMediaQuery(theme.breakpoints.down('sm'));
	const { online } = useContext(networkCtx);
	const [openTransaction, setOpenTransaction] = useState(false);
	const [openMore, setOpenMore] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [transaction, setTransaction] = useState(null);
	const [openAccountPaneMobile, setOpenAccountPaneMobile] = useState(false);
	const [openNotifTrans, setOpenNotifTrans] = useState(false);
	const [hideBookmark, setHideBookmark] = useState(false);
	const [pendingTokens, setPendingTokens] = useState([]);
	const {
		address,
		chainId,
		switchToNetwork,
		disconnect: disconnectMetamask,
		connected,
	} = useMetamask();
	const wrongNetwork = !VALID_CHAINS.includes(chainId);
	const [openModal, setOpenModal] = useState(false);
	const [lowBalanceBanner, setLowBalanceBanner] = useState(false);
	const [currentAddress, setCurrentAddress] = useState(address);
	const [openTypeFormModal, setTypeFormModal] = useState(false);
	const [tabNamePressed, setTabNamePress] = useState(false);
	const { setTransactionError, errorMessage, setErrorMessage } =
		useContext(errorCtx);
	const { balanceFetched, getNativeCoin } = useContext(tokenListCtx);
	const {
		searchModal,
		notInstalledMetamaskModal,
		setNotInstalledMetamaskModal,
	} = useContext(modalsCtx);
	const { destinationChainId } = useContext(chainsCtx);

	// websocket connection variables
	const { latestBlockMethod } = useContext(latestBlockCtx);
	const [subscriptions, setSubscriptions] = useState([]);

	// websocket functions for connecting and disconnecting
	const subscribeToChain = async (chainId, method) => {
		try {
			const subscription = await subscribeToWeb3Socket(
				chainId,
				block => method(block?.number),
				blockNumber => method(blockNumber),
				() => {}, // error handler
			);

			subscriptions.push(subscription);
		} catch (error) {
			// error handler
		}
	};

	const unsubscribeFromChain = async () => {
		subscriptions.forEach(async item => {
			await item.unsubscribe();
		});
		setSubscriptions([]);
	};

	const handleShowModal = () => {
		setOpenModal(true);
	};

	const handleHideModal = () => {
		setOpenModal(false);
	};

	const handleCloseTypeformModal = () => {
		setTypeFormModal(false);
	};

	const swapNetwork = () => {
		switchToNetwork(destinationChainId);
	};

	const handleHideNotifTrans = async (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenNotifTrans(false);

		// Clean pending transactions here
		setPendingTokens([]);
	};

	const handleTransferToken = transTokenState => {
		if (pendingTokens.length === 0) {
			setTransactionError(false);
		}

		const tx = transTokenState[ALL_CHAINS[chainId].transferTxField];
		if (typeof tx === 'object') {
			// most likely to be an error
			setErrorMessage(TRANSACTION_ERROR_MESSAGES[tx.code]);
			return;
		}

		setOpenNotifTrans(true);
		setPendingTokens(existingToken => [...existingToken, transTokenState]);
	};

	const handleTypeformClick = navName => {
		if (navName === 'send_feedback') {
			setTypeFormModal(true);
		}
	};

	const handleTabsButtons = tabName => {
		if (tabName === 'more') {
			setTabNamePress(true);
			setOpenMore(true);
		} else {
			setTabNamePress(false);
			setOpenMore(false);
		}
	};

	const handleShowTransaction = data => {
		setTransaction(data);
		setOpenTransaction(true);
	};

	const handleHideTransaction = () => {
		setOpenTransaction(false);
	};

	const handleRejectionClose = () => {
		setErrorMessage('');
	};

	let tabsContent;
	if (smallScreenSize && !searchModal) {
		tabsContent = <TabsMenu callbackClickTabs={handleTabsButtons} />;
	}

	const logo = (
		<Box
			display={{ xs: 'flex', lg: 'none' }}
			my={{ xs: 1, md: 3 }}
			mx={{ xs: 0, md: 'auto' }}
		>
			<Link component={LinkWithRef} to="/">
				<EnergiLogo className={classes.logo} />
			</Link>
		</Box>
	);

	const appBar = (
		<Box
			className={classes.appBar}
			display="flex"
			justifyContent="space-between"
			alignItems="center"
		>
			{logo}
			<Box display={{ xs: 'block', md: 'none' }}>
				<ChooseWallet
					address={address}
					setOpenAccountPaneMobile={setOpenAccountPaneMobile}
					handleShowTransaction={handleShowTransaction}
				/>
			</Box>
		</Box>
	);

	const notifyTransactionContent = (
		<NotifyTransactionModal
			tokens={pendingTokens}
			show={openNotifTrans}
			handleClose={handleHideNotifTrans}
		/>
	);

	const mainContent = (
		<Box pt={{ xs: 11, sm: 20, md: 0, lg: 7 }}>
			{appBar}
			{notifyTransactionContent}
			<TransactionErrorModal
				onClose={handleRejectionClose}
				message={errorMessage}
			/>
			<SwapAsset swapNetwork={swapNetwork} />
			<ConvertToken
				openMore={openMore}
				callBackTransferToken={handleTransferToken}
			/>
		</Box>
	);

	const rightSideContent = (
		<Box
			className={
				!openAccountPaneMobile
					? classes.rightSideContent
					: classes.rightSideContentSmall
			}
		>
			{connected && !wrongNetwork ? <WalletInfo /> : <ConnectToMetamask />}
			<BridgeHistory
				handleShowTransaction={handleShowTransaction}
				wrongNetwork={wrongNetwork}
			/>
		</Box>
	);

	const warningModalContent = (
		<WarningModal
			show={openModal}
			handleClose={handleHideModal}
			title={t('page.bridge.warning_title')}
			warningText={t('page.bridge.warning')}
			description={t('page.bridge.warning_message')}
			checkBoxText={t('page.bridge.warning_checkbox')}
			buttonText={t('page.bridge.warning_continue_button')}
		/>
	);

	const notMetamaskInstalledModalContent = (
		<NotMetamaskInstalledModal
			show={notInstalledMetamaskModal}
			handleClose={() => setNotInstalledMetamaskModal(false)}
		/>
	);

	const transactionModalContent = () => {
		return (
			// a failed transaction throws an object, that's the type check is for
			transaction &&
			typeof transaction.txHash === 'string' && (
				<TransactionInfoModal
					show={openTransaction}
					handleClose={handleHideTransaction}
					title={t('page.bridge.warning_title')}
					warningText={t('page.bridge.warning')}
					description={t('page.bridge.warning_message')}
					checkBoxText={t('page.bridge.warning_checkbox')}
					buttonText={t('page.bridge.warning_continue_button')}
					data={transaction}
				/>
			)
		);
	};

	let visibilityChange;
	let hidden;
	if (typeof document.hidden !== 'undefined') {
		// Opera 12.10 and Firefox 18 and later support
		hidden = 'hidden';
		visibilityChange = 'visibilitychange';
	} else if (typeof document.msHidden !== 'undefined') {
		hidden = 'msHidden';
		visibilityChange = 'msvisibilitychange';
	} else if (typeof document.webkitHidden !== 'undefined') {
		// chrome
		hidden = 'webkitHidden';
		visibilityChange = 'webkitvisibilitychange';
	}

	const switchNetworkOnActiveTab = async () => {
		if (chainId && !VALID_CHAINS.includes(chainId) && !document[hidden]) {
			switchToNetwork(VALID_CHAINS[0]);
		}
	};

	useEffect(() => {
		if (!localStorageBridge) {
			handleShowModal();
			localStorage.setItem('bridgePage', true);
		}

		window.onbeforeunload = () => {
			disconnectMetamask();
		};

		document.addEventListener(visibilityChange, switchNetworkOnActiveTab);
		return () => {
			document.removeEventListener(
				visibilityChange,
				switchNetworkOnActiveTab,
				true,
			);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chainId]);

	useEffect(() => {
		if (isIdle) {
			disconnectMetamask();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isIdle]);

	useEffect(() => {
		if (online) {
			VALID_CHAINS.forEach(chainId => {
				const method = latestBlockMethod[chainId];
				subscribeToChain(chainId, method);
			});
		} else {
			unsubscribeFromChain();
		}

		// eslint-disable-next-line no-return-assign
		return () => unsubscribeFromChain();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [online]);

	useEffect(() => {
		const nativeCoin = getNativeCoin(chainId);
		if (
			chainId === ENV_ENERGI_CHAINID &&
			balanceFetched &&
			currentAddress === address &&
			nativeCoin.balance
		) {
			const fetchFee = async () => {
				const fee = toBN(await getDepositFee(chainId, address));
				const balance = toBN(nativeCoin?.balance);
				if (fee.gt(balance)) {
					setLowBalanceBanner(true);
				} else {
					setLowBalanceBanner(false);
				}
			};
			fetchFee();
		}

		setLowBalanceBanner(false);
		if (currentAddress !== address) {
			setCurrentAddress(address);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chainId, address, balanceFetched]);

	return (
		<Box style={{ display: 'flex', flexDirection: 'column' }}>
			{!connected && (
				<BookmarkModal
					hideBookmark={hideBookmark}
					setHideBookmark={setHideBookmark}
					url={t('page.bridge.modal_url')}
				/>
			)}
			<Box className={classes.root}>
				{warningModalContent}
				{transactionModalContent()}
				<OfflineModal
					show={!online}
					message={t(online ? 'offline_modal.online' : 'offline_modal.offline')}
				/>
				<LeftSidebar
					{...props}
					callbackClickButton={handleTypeformClick}
					tabOpen={tabNamePressed}
				/>
				<Box
					component="main"
					className={classes.content}
					pb={{ xs: 15, md: 0 }}
				>
					{lowBalanceBanner && <LowBalanceBanner />}
					<Container className={classes.container} maxWidth={false}>
						<Grid alignItems="stretch" container>
							<Grid className={classes.middle} xs={12} md={7} item>
								{mainContent}
							</Grid>
							<Grid className={classes.gridRightSide} md={5} item>
								{rightSideContent}
							</Grid>
						</Grid>
					</Container>
				</Box>
				{tabsContent}
				<TypeformModal
					url="https://energicorelimited.typeform.com/to/DQwD3cdd"
					show={openTypeFormModal}
					handleClose={handleCloseTypeformModal}
				/>
			</Box>
			{notInstalledMetamaskModal && notMetamaskInstalledModalContent}
		</Box>
	);
};

BridgePage.defaultProps = {
	children: null,
};

BridgePage.propTypes = {
	children: PropTypes.node,
};

export default BridgePage;

/* eslint-disable no-nested-ternary */
import React, { useState, useContext, useMemo } from 'react';
import clsx from 'clsx';
import { getWeb3 } from '@energi/utils';
import { getExplorerLink } from 'utils/get-contract';
import PropTypes from 'prop-types';
import {
	Box,
	Snackbar,
	Collapse,
	IconButton,
	CircularProgress,
	Link,
	Tooltip,
	withStyles,
} from '@energi/ui';
import {
	Close as CloseIcon,
	ExpandMore as ExpandMoreIcon,
	ExpandLess as ExpandLessIcon,
	LaunchOutlined as LaunchOutlinedIcon,
	CheckCircle as CheckCircleIcon,
	Update as UpdateIcon,
	Error as ErrorIcon,
} from '@energi/ui/icons';
import useStyles from 'styles/components/common/NotifyTransactionModal';
import errorCtx from 'context/error';
import transactionCtx from 'context/transactions';
import latestBlockCtx from 'context/latestBlock';
import { ALL_CHAINS, SUPPORTED_NETWORK } from 'utils/constants/chains';
import { useIntl } from 'utils/helper';

const CustomTooltip = withStyles(theme => ({
	tooltip: {
		backgroundColor: 'rgba(127, 127, 127)',
		color: '#FFF',
		padding: theme.spacing(1, 1),
		fontSize: 12,
		lineHeight: 1.8,
		fontWeight: 500,
	},
}))(Tooltip);

const CircularProgressBlockchain = ({
	className,
	token,
	setErrorTXNIds,
	setLocalError,
	localError,
	chainId,
	transaction,
}) => {
	const classes = useStyles();
	const [transCompleted, setTransComplete] = useState(false);
	const { transactionError, setTransactionError } = useContext(errorCtx);
	const { latestBlock } = useContext(latestBlockCtx);
	const bhEnergi = latestBlock[SUPPORTED_NETWORK.energi];
	const { transactions } = useContext(transactionCtx);
	const sourceNetwork = ALL_CHAINS[token.sourceChainId];

	const sourceTxnHash = token[sourceNetwork.transferTxField];
	const web3 = getWeb3(chainId);

	if ((!transactionError || !localError) && setLocalError && sourceTxnHash) {
		web3.eth.getTransactionReceipt(sourceTxnHash).then(item => {
			if (item && !item.status) {
				setErrorTXNIds(state => new Set(state).add(sourceTxnHash));
				setTransactionError(true);
				setLocalError(true);
			}
		});
	}

	useMemo(() => {
		if (chainId === token.sourceChainId && !transCompleted && transaction) {
			setTransComplete(true);
		}

		if (
			chainId === token.destinationChainId &&
			!transCompleted &&
			transaction?.settled
		) {
			setTransComplete(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bhEnergi, transactions, transaction?.settled]);

	return (
		<Box display="flex" alignItems="center" justifyContent="space-between">
			{transCompleted ? (
				<CheckCircleIcon className={classes.checkCircleIcon} />
			) : transactionError && localError && !transCompleted ? (
				<ErrorIcon className={classes.errorIcon} />
			) : (
				<CircularProgress
					className={clsx(classes.circularProgress, className)}
					progress={10}
					size={25}
				/>
			)}
		</Box>
	);
};

CircularProgressBlockchain.defaultProps = {
	className: '',
	token: {},
	setErrorTXNIds: null,
	setLocalError: null,
	localError: false,
	chainId: undefined,
	transaction: null,
};

CircularProgressBlockchain.propTypes = {
	className: PropTypes.string,
	token: PropTypes.object,
	setErrorTXNIds: PropTypes.func,
	setLocalError: PropTypes.func,
	localError: PropTypes.bool,
	chainId: PropTypes.number,
	transaction: PropTypes.shape({
		txHash: PropTypes.string,
		settled: PropTypes.shape({
			txHash: PropTypes.string,
			id: PropTypes.string,
		}),
	}),
};

const TransactionRow = ({
	amount,
	symbol,
	tokenNum,
	index,
	setErrorTXNIds,
	isDelayed,
	completedTransfers,
	transaction,
	...rest
}) => {
	const [localError, setLocalError] = useState(false);
	const t = useIntl();
	const classes = useStyles();
	const { transactionError } = useContext(errorCtx);
	const { sourceChainId, destinationChainId } = rest;

	const sourceNetworkName = SUPPORTED_NETWORK[sourceChainId];
	const destinationNetworkName = SUPPORTED_NETWORK[destinationChainId];

	const settled = useMemo(() => {
		return transaction?.settled;
	}, [transaction]);

	let leaveBlockchainText = t(
		`snackbar_notification.leave_${sourceNetworkName}_blockchain`,
	);
	let migratingBlockchainText = t(
		`snackbar_notification.migrating_${destinationNetworkName}_blockchain`,
	);

	if (transactionError && localError && !settled) {
		leaveBlockchainText = `${leaveBlockchainText} ${t(
			'snackbar_notification.blockchain_failed',
		)}`;
		migratingBlockchainText = `${migratingBlockchainText} ${t(
			'snackbar_notification.blockchain_failed',
		)}`;
	}

	if (!transactionError && !localError && settled) {
		leaveBlockchainText = t(
			`snackbar_notification.left_${sourceNetworkName}_blockchain`,
		);
		migratingBlockchainText = t(
			`snackbar_notification.available_${destinationNetworkName}_blockchain`,
		);
	}

	return (
		<Box
			className={classes.row}
			display="flex"
			flexDirection="column"
			// eslint-disable-next-line react/no-array-index-key
			key={index}
		>
			<Box className={classes.transferText}>
				{t('snackbar_notification.transfer')}
				<Box
					component="span"
					className={clsx(classes.symbolAmount, {
						[classes.symbolAmountGreen]:
							completedTransfers.length >= tokenNum || settled,
						[classes.symbolAmountError]:
							transactionError && localError && !settled,
						[classes.symbolAmountDelayed]: isDelayed,
					})}
				>
					{amount} {symbol}
				</Box>
			</Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="0.8rem"
			>
				<Box className={classes.blockchainText}>{leaveBlockchainText}</Box>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					width="100px"
				>
					<Box>
						<Box>
							<CircularProgressBlockchain
								token={rest}
								transaction={transaction}
								setErrorTXNIds={setErrorTXNIds}
								setLocalError={setLocalError}
								localError={localError}
								chainId={sourceChainId}
							/>
						</Box>
					</Box>
					<Box>
						<Link
							className={classes.link}
							href={getExplorerLink(
								sourceChainId,
								rest[ALL_CHAINS[sourceChainId].transferTxField],
								'transaction',
							)}
							target="_blank"
							rel="noreferrer noopener"
						>
							<IconButton>
								<LaunchOutlinedIcon className={classes.openIcon} />
							</IconButton>
						</Link>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="1.6rem"
			>
				<Box className={classes.blockchainText}>{migratingBlockchainText}</Box>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					width="100px"
					className={!settled ? classes.lastBox : null}
				>
					<CustomTooltip
						className={isDelayed ? classes.show : classes.hide}
						disableTouchListener
						title={t('bridge_history.delayed_tooltip')}
						placement="top"
					>
						<UpdateIcon className={classes.delayedIcon} />
					</CustomTooltip>
					<Box className={isDelayed ? classes.hide : classes.show}>
						<CircularProgressBlockchain
							token={rest}
							setErrorTXNIds={setErrorTXNIds}
							transaction={transaction}
							setLocalError={setLocalError}
							localError={localError}
							chainId={destinationChainId}
						/>
					</Box>
					<Box style={{ height: settled ? 'auto' : 25 }}>
						{settled ? (
							<Link
								className={classes.link}
								href={getExplorerLink(
									destinationChainId,
									settled?.txHash,
									'transaction',
								)}
								target="_blank"
								rel="noreferrer noopener"
							>
								<IconButton>
									<LaunchOutlinedIcon disabled className={classes.openIcon} />
								</IconButton>
							</Link>
						) : transactionError && localError ? (
							<ErrorIcon className={classes.errorIcon} />
						) : (
							<CircularProgress
								className={clsx(
									classes.circularProgress,
									classes.lastCircularProgress,
								)}
								progress={10}
								size={25}
							/>
						)}
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

TransactionRow.defaultProps = {
	transaction: null,
};

TransactionRow.propTypes = {
	amount: PropTypes.string.isRequired,
	symbol: PropTypes.string.isRequired,
	tokenNum: PropTypes.number.isRequired,
	index: PropTypes.number.isRequired,
	completedTransfers: PropTypes.array.isRequired,
	setErrorTXNIds: PropTypes.func.isRequired,
	isDelayed: PropTypes.bool.isRequired,
	transaction: PropTypes.shape({
		id: PropTypes.string,
		txHash: PropTypes.string,
		settled: PropTypes.shape({
			txHash: PropTypes.string,
			id: PropTypes.string,
		}),
	}),
};

const NotifyTransactionModal = ({ tokens, handleClose, show }) => {
	const t = useIntl();
	const classes = useStyles();
	const [collapse, setCollapse] = useState(true);
	const { transactionError } = useContext(errorCtx);
	const { transactions } = useContext(transactionCtx);
	const [completedTransfers, setCompletedTransfers] = useState([]);
	const [noDuplicateCompleteTxn, setNoDuplicateCompleteTxn] = useState([]);
	const [errorTXNIds, setErrorTXNIds] = useState(new Set());
	const tokenNum = tokens.length;
	const nDuplComplTxnNum = noDuplicateCompleteTxn.length;
	const strTransaction =
		tokenNum > 1
			? t('snackbar_notification.transactions')
			: t('snackbar_notification.transaction');
	let transactionTitle;

	const handleCompletedTokenTransfer = async pendingToken => {
		setCompletedTransfers([...completedTransfers, pendingToken]);
	};

	const delayedTxns = transactions.filter(
		txn =>
			txn.delayed &&
			tokens.find(pendingTxns => {
				const tokenTxHash =
					pendingTxns[ALL_CHAINS[pendingTxns.sourceChainId].transferTxField];
				return tokenTxHash === txn.txHash;
			}),
	);

	useMemo(() => {
		// loop through completed transfers and remove duplicates then assign to a new variable, use the variable to determine completion
		const nonDuplicateComplete = Array.from(
			new Set(
				completedTransfers.map(tx => {
					const sourceNetwork = ALL_CHAINS[tx.sourceChainId];
					return tx?.[sourceNetwork.transferTxField];
				}),
			),
		);
		setNoDuplicateCompleteTxn(nonDuplicateComplete);
	}, [completedTransfers]);

	const handleExited = () => {
		setCompletedTransfers([]);
		setErrorTXNIds(new Set());
	};

	const handleCollapse = () => {
		setCollapse(!collapse);
	};

	const tokenContent = useMemo(() => {
		return tokens.map(
			({ symbol, amount, ethAddress, nrgAddress, ...rest }, index) => {
				const tokenTxHash =
					rest[ALL_CHAINS[rest.sourceChainId].transferTxField];
				const transaction = transactions.find(
					txn => txn.txHash === tokenTxHash,
				);
				const completed = completedTransfers.find(transaction => {
					const sourceNetwork = ALL_CHAINS[transaction.sourceChainId];
					return transaction[sourceNetwork.transferTxField] === tokenTxHash;
				});
				if (transaction?.settled && !completed) {
					handleCompletedTokenTransfer(rest);
				}
				return (
					<TransactionRow
						amount={amount}
						symbol={symbol}
						tokenNum={tokenNum}
						isDelayed={Boolean(
							delayedTxns.find(delayedTx => delayedTx.txHash === tokenTxHash),
						)}
						// eslint-disable-next-line react/no-array-index-key
						key={index}
						index={index}
						completedTransfers={noDuplicateCompleteTxn}
						setErrorTXNIds={setErrorTXNIds}
						transaction={transaction}
						{...rest}
					/>
				);
			},
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [transactions, tokens]);

	if (delayedTxns.length) {
		transactionTitle = `${delayedTxns.length} ${strTransaction} ${t(
			'snackbar_notification.transaction_delayed',
		)}`;
	} else if (nDuplComplTxnNum < tokenNum && !transactionError) {
		transactionTitle = `${tokenNum - nDuplComplTxnNum} ${strTransaction} ${t(
			'snackbar_notification.transaction_pending',
		)}`;
	} else if (transactionError) {
		transactionTitle = `${errorTXNIds.size} ${strTransaction} ${t(
			'snackbar_notification.transaction_failed',
		)}`;
	} else {
		transactionTitle = `${nDuplComplTxnNum} ${strTransaction} ${t(
			'snackbar_notification.transaction_complete',
		)}`;
	}

	return (
		<Snackbar
			className={classes.root}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			open={show}
			onClose={handleClose}
			TransitionProps={{
				onExited: handleExited,
			}}
		>
			<Box className={classes.container}>
				<Box
					className={clsx(classes.header, {
						[classes.headerGreen]: nDuplComplTxnNum >= tokenNum,
						[classes.headerError]: transactionError,
						[classes.headerDelayed]: delayedTxns.length,
					})}
				>
					<Box className={classes.title}>{transactionTitle}</Box>
					<Box display="flex" ml="auto">
						<IconButton
							onClick={handleCollapse}
							className={clsx({
								[classes.iconswhite]: nDuplComplTxnNum >= tokenNum,
								[classes.iconsblack]: nDuplComplTxnNum < tokenNum,
							})}
						>
							{collapse ? <ExpandMoreIcon /> : <ExpandLessIcon />}
						</IconButton>
						<IconButton
							onClick={handleClose}
							className={clsx({
								[classes.iconswhite]: nDuplComplTxnNum >= tokenNum,
								[classes.iconsblack]: nDuplComplTxnNum < tokenNum,
							})}
						>
							<CloseIcon />
						</IconButton>
					</Box>
				</Box>
				<Collapse in={collapse}>
					<Box className={classes.content}>
						<Box className={classes.scrollContent}>{tokenContent}</Box>
					</Box>
				</Collapse>
			</Box>
		</Snackbar>
	);
};

NotifyTransactionModal.defaultProps = {
	tokens: [
		{
			symbol: 'Unknown token',
			amount: '0.0',
		},
	],
	show: false,
	handleClose: null,
};

NotifyTransactionModal.propTypes = {
	tokens: PropTypes.arrayOf(
		PropTypes.shape({
			symbol: PropTypes.string.isRequired,
			amount: PropTypes.string.isRequired,
		}),
	),
	show: PropTypes.bool,
	handleClose: PropTypes.func,
};

export default NotifyTransactionModal;

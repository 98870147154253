import { toBN } from '@energi/utils';
import { utils as web3utils } from 'web3';
import sdk from '@energi/energi-sdk';
import { GAS_LIMIT, GAS_UNIT_AMOUNT } from 'utils/constants';
import { getCashierContract, getTokenContract } from 'utils/get-contract';

export const enoughBalance = (allowance, amountToSpend) =>
	!!allowance && !!amountToSpend && toBN(amountToSpend).lte(toBN(allowance));

// check the currentTokenAllowance the tokenCashier has approval to spend for
export const currentTokenAllowance = async (chainId, tokenAddress, address) => {
	const contract = getTokenContract(chainId, tokenAddress);
	const cashierContract = getCashierContract(chainId);
	const spender = cashierContract.options.address;

	return contract.methods.allowance(address, spender).call({ from: address });
};

// check that tokenCashier has approval to spend token to be transferred to energi or ethereum, either way
export const hasTokenApproval = async (
	chainId,
	tokenAddress,
	address,
	amountToApprove, // in wei
) => {
	const allowance = await currentTokenAllowance(chainId, tokenAddress, address);
	return enoughBalance(allowance, amountToApprove);
};

export const approveToken = async (
	chainId,
	tokenAddress,
	address,
	amountToApprove,
	sendTx,
) => {
	let tx;
	try {
		const contract = getTokenContract(chainId, tokenAddress);
		const cashierContract = getCashierContract(chainId);
		const spender = cashierContract.options.address;
		const isApproved = await hasTokenApproval(
			chainId,
			tokenAddress,
			address,
			amountToApprove,
		);

		if (!isApproved) {
			const transactionData = contract.methods.approve(
				spender,
				amountToApprove,
			);
			const data = transactionData.encodeABI();
			const txData = {
				from: address,
				to: contract.options.address,
				gasLimit: web3utils.toHex(GAS_LIMIT),
				data,
			};
			tx = await sendTx(txData);
			if (typeof tx !== 'string' && tx?.code === 4001) {
				throw new Error('Transaction rejected');
			} else {
				return tx;
			}
		}
	} catch (err) {
		throw new Error(err.message);
	}
	return tx;
};

// check the getDepositFee the tokenCashier
export const getDepositFee = async (chainId, address) => {
	const cashierContract = getCashierContract(chainId);
	const depositFee = await cashierContract.methods
		.depositFee()
		.call({ from: address });
	return depositFee;
};

export const transferToken = async (
	chainId,
	tokenAddress,
	address,
	amount,
	sendTx,
	gwei,
) => {
	const isWrapped = false;
	const cashierContract = getCashierContract(chainId);
	const tx = await cashierContract.methods.deposit(
		tokenAddress,
		amount,
		isWrapped,
	);
	const data = tx.encodeABI();
	const txData = {
		from: address,
		to: cashierContract.options.address,
		gasLimit: web3utils.toHex(GAS_LIMIT),
		gas: web3utils.toHex(GAS_UNIT_AMOUNT),
		gasPrice: web3utils.toHex(gwei),
		data,
	};

	// check the getDepositFee the tokenCashier
	const depositFee = await cashierContract.methods
		.depositFee()
		.call({ from: address });

	if (depositFee > 0) {
		amount = toBN(amount).add(toBN(depositFee));
	}

	if (tokenAddress === sdk.contracts.NATIVE_DEPOSIT_ADDRESSES[chainId]) {
		// Bridging ETH or NRG native Coins -> needs also to spend the same amount through value in the same tx
		txData.value = web3utils.toHex(amount);
	} else {
		txData.value = web3utils.toHex(depositFee);
	}
	const transactionStatus = await sendTx(txData);
	if (
		typeof transactionStatus !== 'string' &&
		transactionStatus?.code === 4001
	) {
		throw new Error('Transaction rejected');
	} else {
		return transactionStatus;
	}
};

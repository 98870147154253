import { makeStyles, alpha } from '@energi/ui';

export default makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		padding: theme.spacing(3),
		borderRadius: 16,
		backgroundColor: alpha(theme.palette.common.white, 0.16),
		overflowY: 'hidden',
		height: 310,
		minHeight: 310,
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-around',
		scrollBehavior: 'smooth',
		height: '100%',
	},
	heading: {
		fontSize: 16,
		fontWeight: 600,
	},
	secondary: {
		fontSize: 16,
		fontWeight: 400,
		opacity: 0.7,
		marginBottom: theme.spacing(1),
	},
	metamaskIcon: {
		width: 64,
		height: 64,
	},
	connectButton: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.secondary.dark,
		padding: theme.spacing(0.8, 5),
		fontWeight: 600,
		fontSize: 14,
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.secondary.dark,
		},
	},
	downloadLink: {
		color: theme.palette.primary.main,
		textDecoration: 'none',
		fontSize: 14,
	},
	footerText: {
		fontSize: 14,
		fontWeight: 400,
	},
}));

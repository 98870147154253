import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Modal as MuiModal, Backdrop, IconButton, Box, Fade } from '@energi/ui';
import { Close as CloseIcon } from '@energi/ui/icons';
import Button from 'components/common/Button';

import useStyles from 'styles/components/common/Modal';

const Modal = props => {
	const { className, children, button, handleClose, show, ...other } = props;
	const classes = useStyles();

	const buttonContent = button.map(({ text, ...buttonProps }) => (
		<Button {...buttonProps} key={text}>
			{text}
		</Button>
	));

	return (
		<MuiModal
			className={clsx(classes.root, className)}
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			open={show}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
				className: classes.backdrop,
			}}
			{...other}
		>
			<Fade in={show}>
				<Box className={clsx(classes.paper, className)}>
					<IconButton
						className={classes.closeButton}
						aria-label="close"
						onClick={handleClose}
					>
						<CloseIcon />
					</IconButton>
					{children}
					{buttonContent}
				</Box>
			</Fade>
		</MuiModal>
	);
};

Modal.defaultProps = {
	className: '',
	show: false,
	handleClose: null,
	button: [
		{
			text: 'Ok',
		},
	],
};

Modal.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
	show: PropTypes.bool,
	handleClose: PropTypes.func,
	button: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.node.isRequired,
			loading: PropTypes.bool,
			onClick: PropTypes.func,
		}),
	),
};

export default Modal;

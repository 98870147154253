import { makeStyles, alpha } from '@energi/ui';

export default makeStyles(theme => ({
	root: {
		padding: theme.spacing(3, 0),
		minHeight: 310,
		background: alpha(theme.palette.common.white, 0.16),
		borderRadius: 16,
		width: '100%',
		textAlign: 'center',
		'@media (max-width: 600px)': {
			margin: theme.spacing(0, 1.5),
			marginBottom: theme.spacing(2),
			width: 'calc(100% - 24px)',
		},
	},
	innerBox: {
		display: 'flex',
		height: '100%',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	metamaskIcon: {
		width: theme.spacing(6),
		height: theme.spacing(6),
	},
	metamaskTitle: {
		fontSize: 21,
		fontWeight: 600,
		marginLeft: theme.spacing(1),
		letterSpacing: 2,
		textTransform: 'uppercase',
		verticalAlign: 'middle',
	},
	flexBox: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	iconBox: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-evenly',
	},
	iconBoxInner: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		color: alpha(theme.palette.common.white, 0.7),
		'&:hover': {
			textDecoration: 'underline',
			color: alpha(theme.palette.common.white, 1),
		},
	},
	iconBoxIcon: {
		marginRight: theme.spacing(1),
		'& > svg': {
			verticalAlign: 'middle',
		},
	},
	networkText: {
		color: alpha(theme.palette.common.white, 0.5),
		fontSize: 16,
		marginTop: theme.spacing(2),
		paddingBottom: 0,
		marginBottom: 0,
	},
	addressBox: {
		fontSize: 14,
		fontWeight: 600,
		paddingTop: 0,
		marginTop: 0,
	},
	disconnectButton: {
		color: theme.palette.green[500],
		letterSpacing: 1.25,
		borderColor: 'transparent',
		fontWeight: 600,
		fontSize: 14,
		padding: theme.spacing(0.8, 2),
		marginTop: theme.spacing(2),
		'&:hover': {
			backgroundColor: alpha(theme.palette.green[500], 0.1),
			textDecoration: 'none',
		},
	},
	skeleton: {
		display: 'inline-block',
	},
}));

import { makeStyles, alpha } from '@energi/ui';

export default makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		padding: theme.spacing(3),
		borderRadius: 16,
		backgroundColor: alpha(theme.palette.common.white, 0.16),
		height: '100%',
		overflowY: 'hidden',
		marginTop: theme.spacing(3),
		[theme.breakpoints.up('lg')]: {
			width: '100%',
		},
		[theme.breakpoints.down('xs')]: {
			width: '95%',
			margin: '1rem auto',
			padding: theme.spacing(2),
			marginTop: -8,
		},
	},
	sectionTitle: {
		fontSize: 20,
		fontWeight: 600,
		margin: 0,
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: theme.spacing(2),
		scrollBehavior: 'smooth',
		maxHeight: '100vh',
	},
	transactionsRange: {
		'& > p': {
			fontStyle: 'normal',
			fontWeight: 'normal',
			letterSpacing: 0.25,
			color: alpha(theme.palette.common.white, 0.7),
		},
	},
	messageContainer: {
		margin: 'auto',
		textAlign: 'center',
		marginTop: theme.spacing(10),
	},
	title: {
		fontSize: 20,
		fontWeight: 600,
		marginBottom: theme.spacing(3),
		color: alpha(theme.palette.common.white, 0.7),
	},
	content: {
		margin: 0,
		fontSize: 16,
		letterSpacing: 0.5,
		lineHeight: 2.4,
		color: alpha(theme.palette.common.white, 0.7),
	},
	scrollable: {
		overflowY: 'scroll',
	},
	alignCenter: {
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
	},
	walletIconContainer: {
		padding: theme.spacing(2),
		borderRadius: '50%',
		backgroundColor: '#666666',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: 108,
		height: 108,
		marginBottom: theme.spacing(3),
		marginTop: theme.spacing(-20),
	},
	walletIcon: {
		width: 44,
		height: 44,
	},
	connectToWallet: {
		textTransform: 'none',
		padding: 0,
		color: theme.palette.primary.main,
		backgroundColor: 'transparent',
		fontSize: 16,
		verticalAlign: 'baseline',
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	txHistoryContainer: {
		paddingBottom: 10,
	},
	loadingCircleBox: {
		display: 'flex',
		justifyContent: 'center',
		position: 'absolute',
		bottom: 10,
		transform: 'translateX(-50%)',
		left: '50%',
		'& > span': {
			margin: '0px 2px',
		},
	},
}));

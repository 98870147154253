import React from 'react';
import PropTypes from 'prop-types';
import { Modal, IconButton, Link } from '@energi/ui';
import { Close as CloseIcon } from '@energi/ui/icons';
import LinkWithRef from 'components/common/LinkWithRef';
import useStyles from 'styles/components/common/NotMetamaskInstalledModal';
import MetamaskLogo from 'assets/images/metamask-fox-title.svg';
import { useIntl } from 'utils/helper';

const NotMetamaskInstalledModal = ({ handleClose, show }) => {
	const classes = useStyles();
	const t = useIntl();

	return (
		<Modal
			open={show}
			onClose={handleClose}
			BackdropProps={{
				className: classes.backdrop,
			}}
		>
			<div className={classes.root}>
				<div className={classes.container}>
					<header className={classes.header}>
						<IconButton className={classes.closeButton} onClick={handleClose}>
							<CloseIcon />
						</IconButton>
						<MetamaskLogo className={classes.metamaskLogo} />
					</header>
					<div className={classes.body}>
						{t('not_installed_metamask_modal.wrong_network_desc1')}{' '}
						<Link
							component={LinkWithRef}
							className={classes.docsLink}
							to="https://wiki.energi.world/en/guides/getting-started-with-metamask"
							target="_blank"
							rel="noopener noreferrer"
						>
							{t('not_installed_metamask_modal.wrong_network_docs')}
						</Link>{' '}
						{t('not_installed_metamask_modal.wrong_network_desc2')}
					</div>
					<div className={classes.line} />
					<div className={classes.footer}>
						{t('not_installed_metamask_modal.no_metamask_text')}{' '}
						<Link
							component={LinkWithRef}
							className={classes.downloadLink}
							to="https://metamask.io/download.html"
							target="_blank"
							rel="noopener noreferrer"
						>
							{t('not_installed_metamask_modal.metamask_download')}
						</Link>
					</div>
				</div>
			</div>
		</Modal>
	);
};

NotMetamaskInstalledModal.defaultProps = {
	show: false,
	handleClose: null,
};

NotMetamaskInstalledModal.propTypes = {
	show: PropTypes.bool,
	handleClose: PropTypes.func,
};

export default NotMetamaskInstalledModal;

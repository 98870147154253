import { useMemo, useState } from 'react';
import { Metamask, useMetamask } from '@energi/energi-wallet';
import sdk from '@energi/energi-sdk';

const useSelectTokens = tokens => {
	const [selects, setSelects] = useState([]);
	const { chainId } = useMetamask();
	const tokensLength = tokens.length;

	useMemo(() => {
		setSelects(
			tokens.map(token => ({
				...token,
				selected: false,
				disabled: token.address === sdk.ZERO_ADDRESS,
			})),
		);
	}, [tokensLength, chainId]); // eslint-disable-line react-hooks/exhaustive-deps

	const toggle = address => {
		if (address === sdk.ZERO_ADDRESS) {
			return;
		}
		const newSelects = [...selects];
		const index = newSelects.findIndex(item => item.address === address);
		newSelects[index] = {
			...newSelects[index],
			selected: !newSelects[index].selected,
		};
		setSelects(newSelects);
	};

	const reset = () => {
		const newSelects = selects.map(item => ({ ...item, selected: false }));
		setSelects(newSelects);
	};

	const addToMetamask = () => {
		const tokens = selects.filter(item => item.selected);
		tokens.forEach(token => {
			let logo = token.icon;
			// if not base64, replace a full url instead
			if (!token.icon.includes('data:image/svg+xml;base64')) {
				logo = `${window.location.origin}/${token.icon}`;
			}
			Metamask.addNewToken({
				...token,
				image: logo,
			});
		});
	};

	const selectedToken = address =>
		selects.find(item => item.address === address);
	const selectedCount = selects.filter(item => item.selected).length;

	return {
		toggle,
		reset,
		selectedToken,
		addToMetamask,
		selectedCount,
	};
};

export default useSelectTokens;

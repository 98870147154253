import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Snackbar, IconButton, Alert as MuiAlert } from '@energi/ui';
import { Close as CloseIcon } from '@energi/ui/icons';
import useStyles from 'styles/components/common/TransactionErrorModal';

const Alert = props => {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const TransactionErrorModal = ({
	onClose,
	disappearTimeInSeconds,
	message,
}) => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [timeoutState, setTimeoutState] = useState();

	const handleClose = () => {
		setOpen(false);
		onClose();
		if (timeoutState) {
			clearTimeout(timeoutState);
			setTimeoutState(null);
		}
	};

	useEffect(() => {
		if (message) {
			setOpen(true);
			const tempTimeout = setTimeout(() => {
				handleClose();
			}, disappearTimeInSeconds * 1000); // converting to miliseconds
			setTimeoutState(tempTimeout);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [message]);

	return (
		<Snackbar
			className={classes.root}
			open={open}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
		>
			<Alert
				action={
					<IconButton
						aria-label="close"
						color="inherit"
						size="small"
						onClick={handleClose}
						className={classes.button}
					>
						<CloseIcon fontSize="inherit" className={classes.icon} />
					</IconButton>
				}
				className={classes.alert}
				classes={{
					message: classes.message,
					icon: classes.blankIcon,
				}}
			>
				{message}
			</Alert>
		</Snackbar>
	);
};

TransactionErrorModal.defaultProps = {
	onClose: () => {},
	disappearTimeInSeconds: 6,
	message: '',
};

TransactionErrorModal.propTypes = {
	onClose: PropTypes.func,
	disappearTimeInSeconds: PropTypes.number,
	message: PropTypes.string,
};

export default TransactionErrorModal;

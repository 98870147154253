const REG_CLEANUP_APPENDED = /^0+(.*)/;

const amountToWei = (amount, decimals) => {
	if (typeof amount !== 'string' || !amount || typeof decimals !== 'number') {
		return '0';
	}
	const parts = amount.replace(',', '.').split('.');
	const firstPart = parts[0] === '0' ? '' : parts[0];
	let secondPart = decimals
		? (parts[1] || '0').padEnd(decimals, '0').substr(0, decimals)
		: '';

	// cleanup leading zero's if needed
	if (!firstPart && secondPart) {
		const match = secondPart.match(REG_CLEANUP_APPENDED);
		if (match) {
			[, secondPart] = match;
		}
	}

	return firstPart + secondPart;
};

export default amountToWei;

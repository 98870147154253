import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
	Modal as MuiModal,
	Backdrop,
	FormControlLabel,
	Checkbox,
	Fade,
} from '@energi/ui';
import Button from 'components/common/Button';
import { Warning as WarningIcon } from '@energi/ui/icons';
import useStyles from 'styles/components/common/WarningModal';

const WarningModal = props => {
	const {
		className,
		handleClose,
		show,
		title,
		warningText,
		description,
		buttonText,
		checkBoxText,
	} = props;
	const classes = useStyles();
	const [checked, setChecked] = useState(false);

	const handleChange = event => {
		setChecked(event.target.checked);
	};

	return (
		<MuiModal
			className={clsx(classes.root, className)}
			open={show}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
				className: classes.backdrop,
			}}
		>
			<Fade in={show}>
				<div className={clsx(classes.paper, className)}>
					<div className={classes.header}>
						<WarningIcon className={classes.icon} />
						<div className={classes.title}>{title}</div>
					</div>
					<div className={classes.content}>
						<div className={classes.description}>
							<div className={classes.warningText}>{warningText}</div>
							{description}
						</div>
						<div className={classes.line} />
						<FormControlLabel
							className={classes.checkbox}
							control={
								<Checkbox
									checked={checked}
									onChange={handleChange}
									name="checked"
									color="primary"
								/>
							}
							label={checkBoxText}
						/>

						<div className={classes.buttonWrap}>
							<Button
								className={classes.button}
								disabled={!checked}
								variant="contained"
								onClick={handleClose}
								disableElevation
							>
								{buttonText}
							</Button>
						</div>
					</div>
				</div>
			</Fade>
		</MuiModal>
	);
};

WarningModal.defaultProps = {
	className: '',
	show: false,
	handleClose: null,
};

WarningModal.propTypes = {
	className: PropTypes.string,
	show: PropTypes.bool,
	handleClose: PropTypes.func,
	title: PropTypes.string.isRequired,
	warningText: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	buttonText: PropTypes.string.isRequired,
	checkBoxText: PropTypes.string.isRequired,
};

export default WarningModal;

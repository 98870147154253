import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@energi/ui';
import { useMetamask } from '@energi/energi-wallet';
import { SwapHoriz as SwapHorizIcon } from '@energi/ui/icons';
import EnergiSwapLogo from 'assets/images/energi-swap-logo.svg';
import EnergiSwapIcon from 'assets/images/energi-swap-icon.svg';
import EthereumSwapLogo from 'assets/images/ethereum-swap-logo.svg';
import EthereumSwapIcon from 'assets/images/ethereum-swap-icon.svg';
import chainsCtx from 'context/chains';
import useStyles from 'styles/components/bridge/SwapAsset';
import {
	ENV_ETHEREUM_CHAINID,
	ENV_ENERGI_CHAINID,
	SUPPORTED_NETWORK,
} from 'utils/constants/chains';
import { useIntl } from 'utils/helper';

const SwapAsset = ({ swapNetwork }) => {
	const classes = useStyles();
	const { chainId } = useMetamask();
	const t = useIntl();
	const { sourceChainId, destinationChainId } = useContext(chainsCtx);

	const assets = {
		[ENV_ENERGI_CHAINID]: {
			sourceSwapLogo: <EnergiSwapLogo className={classes.sourceChainLogo} />,
			destinationSwapLogo: (
				<EnergiSwapLogo className={classes.destinationChainLogo} />
			),
			icon: <EnergiSwapIcon className={classes.assetIcon} />,
			topTextClass: classes.energiText,
			assetNameClass: classes.energiAssetName,
			boxClass: classes.energiBox,
		},
		[ENV_ETHEREUM_CHAINID]: {
			sourceSwapLogo: <EthereumSwapLogo className={classes.sourceChainLogo} />,
			destinationSwapLogo: (
				<EthereumSwapLogo className={classes.destinationChainLogo} />
			),
			icon: <EthereumSwapIcon className={classes.assetIcon} />,
			topTextClass: classes.ethereumText,
			assetNameClass: classes.ethereumAssetName,
			boxClass: classes.ethereumBox,
		},
	};

	const sourceAsset = assets[sourceChainId];
	const destinationAsset = assets[destinationChainId];

	const sourceChainContent = (
		<Box
			className={clsx(classes.fromBox, sourceAsset?.boxClass)}
			p={{ xs: 1, sm: 3 }}
		>
			{sourceAsset?.sourceSwapLogo}
			<Box className={sourceAsset?.topTextClass}>{t('swap_asset.from')}</Box>
			<Box className={classes.iconNameWrap}>
				<Box my="auto">{sourceAsset?.icon}</Box>
				<Box className={sourceAsset?.assetNameClass}>
					{SUPPORTED_NETWORK[sourceChainId] &&
						t(`swap_asset.${SUPPORTED_NETWORK[sourceChainId]}`)}
				</Box>
			</Box>
		</Box>
	);

	const destinationChainContent = (
		<Box
			className={clsx(classes.toBox, destinationAsset?.boxClass)}
			p={{ xs: 1, sm: 3 }}
		>
			{destinationAsset?.destinationSwapLogo}
			<Box
				className={destinationAsset?.topTextClass}
				left={{
					xs: 16,
					sm: 35,
				}}
			>
				{t('swap_asset.to')}
			</Box>
			<Box
				className={classes.iconNameWrap}
				left={{
					xs: 16,
					sm: 35,
				}}
			>
				<Box my="auto">{destinationAsset?.icon}</Box>
				<Box className={destinationAsset?.assetNameClass}>
					{SUPPORTED_NETWORK[destinationChainId] &&
						t(`swap_asset.${SUPPORTED_NETWORK[destinationChainId]}`)}
				</Box>
			</Box>
		</Box>
	);

	const swapButtonContent = (
		<Box className={classes.swapButtonWrap}>
			<Box className={classes.swapBox}>
				<IconButton
					className={classes.swapIconButton}
					onClick={swapNetwork}
					aria-label="Swap asset"
				>
					<SwapHorizIcon className="swapHorizIcon" />
				</IconButton>
			</Box>
		</Box>
	);

	let containerClass;
	if (!chainId) {
		containerClass = classes.swapHidden;
	}
	return (
		<Box className={containerClass} display="flex" position="relative">
			{sourceChainContent}
			{swapButtonContent}
			{destinationChainContent}
		</Box>
	);
};

SwapAsset.propTypes = {
	swapNetwork: PropTypes.func.isRequired,
};

export default SwapAsset;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Paper, Tabs, Tab, Link } from '@energi/ui';
import EnergiSwapIcon from 'assets/images/energiswap.svg';
import {
	SwapHoriz as SwapHorizIcon,
	BarChart as BarChartIcon,
	MoreHoriz as MoreHorizIcon,
} from '@energi/ui/icons';
import useStyles from 'styles/components/common/TabsMenu';
import config from 'config/config';
import { useIntl } from 'utils/helper';

const { ENERGISWAP_URL, ANALYTICS_URL } = config;

const TabsMenus = ({ callbackClickTabs }) => {
	const t = useIntl();
	const classes = useStyles();
	const [value, setValue] = useState(0);

	const handleBridgeItem = () => {
		callbackClickTabs('bridge');
	};
	const handleMoreItem = () => {
		callbackClickTabs('more');
	};

	const MENU = [
		{ name: t('tabs_menu.nav.bridge'), link: '', onClick: handleBridgeItem },
		{
			name: t('tabs_menu.nav.energiswap'),
			link: ENERGISWAP_URL,
		},
		{
			name: t('tabs_menu.nav.analytics'),
			link: ANALYTICS_URL,
		},
		{
			name: t('tabs_menu.nav.more'),
			link: '',
			onClick: handleMoreItem,
		},
	];

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const getTabIcon = index => {
		switch (index) {
			case 0:
				return <SwapHorizIcon />;
			case 1:
				return <EnergiSwapIcon />;
			case 2:
				return <BarChartIcon />;
			case 3:
				return <MoreHorizIcon />;
			default:
				return <></>;
		}
	};

	return (
		<Paper className={classes.paperTabs} square>
			<Tabs
				className={classes.tabs}
				value={value}
				onChange={handleChange}
				variant="fullWidth"
				indicatorColor="secondary"
				textColor="secondary"
			>
				{MENU.map((tab, index) => (
					<Tab
						component={Link}
						className={clsx(classes.tab)}
						icon={getTabIcon(index)}
						label={tab.name}
						{...(tab.link !== '' && {
							href: tab.link,
							target: '_blank',
							rel: 'noreferrer',
						})}
						{...tab}
						key={tab.name}
					/>
				))}
			</Tabs>
		</Paper>
	);
};

TabsMenus.defaultProps = {
	callbackClickTabs: null,
};

TabsMenus.propTypes = {
	callbackClickTabs: PropTypes.func,
};

export default TabsMenus;

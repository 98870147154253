import { makeStyles, alpha, lighten } from '@energi/ui';

export default makeStyles(theme => ({
	textFieldAmount: {
		'& .MuiInputBase-root': {
			fontSize: 34,
			fontWeight: 600,
			height: 'auto',
			color: theme.palette.common.white,
			backgroundColor: theme.palette.grey[800],
			borderRadius: 6,
			lineHeight: 'normal',
			padding: 0,
			'&.Mui-focused .MuiInputBase-input::placeholder': {
				color: theme.palette.grey[200],
			},
			'&.Mui-focused .MuiInputBase-input': {
				color: theme.palette.common.white,
			},
			'&.Mui-error .MuiInputBase-input': {
				color: theme.palette.common.white,
			},
			'&.Mui-error': {
				borderWidth: '1px',
				borderStyle: 'solid',
				borderColor: theme.palette.red[500],
			},
			'&:hover': {
				backgroundColor: lighten(theme.palette.grey[800], 0.08),
			},
		},
		'& .MuiInputBase-input': {
			padding: theme.spacing(3.5, 0, 1.25, 2),
			color: theme.palette.grey[200],
		},
		'& .MuiInputLabel-filled.MuiInputLabel-shrink': {
			transform: 'translate(18px, 10px) scale(0.75)',
		},
		'& .MuiFormLabel-filled + .MuiInputBase-root > input': {
			color: theme.palette.common.white,
		},

		'& .textFieldAmount_balance': {
			position: 'absolute',
			top: 5,
			right: 12,
			fontSize: 14,
			color: theme.palette.grey[200],
			fontWeight: 600,
			lineHeight: 'normal',
			marginLeft: 'auto',
			[theme.breakpoints.up('sm')]: {
				fontSize: 16,
			},
		},
		'& .textFieldAmount_max': {
			backgroundColor: alpha(theme.palette.green[500], 0.2),
			color: '#00D546',
			padding: theme.spacing(0.5, 2),
			marginRight: theme.spacing(1.5),
		},
		'& .MuiInputBase-root::placeholder': {
			fontSize: 16,
			fontWeight: 600,
			color: theme.palette.common.white,
		},
		'& .textFieldAmount_label': {
			fontSize: 16,
			fontWeight: 600,
			color: theme.palette.common.white,
			'&.Mui-focused': {
				color: theme.palette.green[500],
			},
			'&.Mui-error': {
				color: theme.palette.red[500],
			},
		},
		'& .MuiFormHelperText-root': {
			color: theme.palette.red[500],
		},
	},
	maxButtonWrap: {
		marginTop: theme.spacing(2),
		marginLeft: 'auto',
	},
	fee: {
		fontSize: 14,
		fontWeight: 600,
		color: alpha(theme.palette.common.white, 0.7),
	},
	feeAmount: {
		fontSize: 14,
		fontWeight: 600,
		color: alpha(theme.palette.common.white, 0.7),
	},
	checkboxConfirm: {
		position: 'absolute',
		top: -6,
		left: 0,
		'&.MuiCheckbox-colorSecondary.Mui-checked': {
			color: theme.palette.common.white,
		},
	},
	checkBoxLabel: {
		position: 'relative',
		paddingLeft: theme.spacing(5),
	},
	learnMore: {
		fontSize: 16,
		padding: 0,
		textTransform: 'none',
		letterSpacing: 'normal',
		'&:hover, &:focus': {
			background: 'none!important',
			boxShadow: 'none!important',
		},
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		borderRadius: 6,
		maxWidth: 473,
		width: '100%',
		padding: theme.spacing(2),
		margin: theme.spacing(3, 'auto', 0, 'auto'),
		backgroundColor: theme.palette.secondary.dark,
		[theme.breakpoints.up('xl')]: {
			maxWidth: 673,
			padding: theme.spacing(3),
		},
	},
	blockchainButton: {
		fontWeight: 600,
	},
	approvingNetworkButton: {
		color: `${theme.palette.grey[1000]}!important`,
		backgroundColor: `${theme.palette.primary.main}!important`,
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}!important`,
		},
		'& .MuiButton-label': {
			width: 'auto',
			'&::after': {
				position: 'relative',
				top: -5,
				content: '" ."',
				animation: `$dotAnim 1s steps(5, end) infinite`,
				fontSize: 25,
				lineHeight: 1,
			},
		},
	},
	approveTokenText: {
		fontSize: 20,
		fontWeight: 600,
		textAlign: 'center',
		margin: theme.spacing(3, 0, 1, 0),
		[theme.breakpoints.up('xl')]: {
			padding: theme.spacing(0, 1),
		},
	},
	'@keyframes dotAnim': {
		'0%, 20%': {
			color: alpha(theme.palette.common.black, 0),
			textShadow: `.25em 0 0 ${alpha(
				theme.palette.common.black,
				0,
			)}, .5em 0 0 ${alpha(theme.palette.common.black, 0)}`,
		},
		'40%': {
			color: theme.palette.common.black,
			textShadow: `.25em 0 0 ${alpha(
				theme.palette.common.black,
				0,
			)}, .5em 0 0 ${alpha(theme.palette.common.black, 0)}`,
		},
		'60%': {
			textShadow: `.25em 0 0 ${theme.palette.grey[1000]}, .5em 0 0 ${alpha(
				theme.palette.common.black,
				0,
			)}`,
		},
		'80%, 100%': {
			textShadow: `.25em 0 0 ${theme.palette.grey[1000]}, .5em 0 0 ${theme.palette.grey[1000]}`,
		},
	},
}));

import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
	Box,
	Button,
	ListItem,
	IconButton,
	InputAdornment,
	TextField,
	Tooltip,
	CircularProgress,
	Fade,
	Backdrop,
	Checkbox,
} from '@energi/ui';
import {
	ArrowDropDown as ArrowDropDownIcon,
	Search as SearchIcon,
	Close as CloseIcon,
	ErrorOutline as ErrorOutlineIcon,
	AddCircleOutline as AddCircleOutlineIcon,
	KeyboardBackspace as KeyboardBackspaceIcon,
} from '@energi/ui/icons';
import { formatBN, isMobile } from '@energi/utils';
import { useMetamask } from '@energi/energi-wallet';
import sdk from '@energi/energi-sdk';
import tokenListCtx from 'context/tokenlist';
import modalsCtx from 'context/modals';
import chainsCtx from 'context/chains';
import DefaultLogo from 'assets/tokens/DefaultTokenIcon.svg';
import useStyles from 'styles/components/bridge/SelectTokenList';
import { ALL_CHAINS, VALID_CHAINS } from 'utils/constants/chains';
import { toFixedDecimals, useIntl } from 'utils/helper';
import useSelectTokens from 'hooks/useSelectTokens';

const SelectTokenList = ({ callBackResult, openMore }) => {
	const t = useIntl();
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const { chainId, connected } = useMetamask();
	const [searchValue, setSearchValue] = useState('');
	const {
		tokenList: allTokenList,
		token,
		setTokenId,
		assetsFetched,
	} = useContext(tokenListCtx);
	const { setSearchModal } = useContext(modalsCtx);
	const { destinationChainId } = useContext(chainsCtx);
	const wrongNetwork = !VALID_CHAINS.includes(chainId);
	const [selectMode, setSelectMode] = useState(false);
	const [selectConfirmation, setSelectConfirmation] = useState();

	const tokenList = allTokenList[chainId] ? allTokenList[chainId] : [];
	const { toggle, selectedToken, selectedCount, addToMetamask, reset } =
		useSelectTokens(tokenList);

	const handleOpen = () => {
		setOpen(true);
		setSearchModal(true);
	};

	const handleClose = () => {
		setOpen(false);
		setSearchModal(false);
		setSearchValue('');
	};

	const handleSearchChange = e => {
		setSearchValue(e.target.value);
	};

	const handleSelectClick = token => {
		if (callBackResult) {
			setTokenId(token.id);
			callBackResult(token);
		}
		handleClose();
		setSearchValue('');
	};

	const addTokens = () => {
		addToMetamask();
		setSelectConfirmation(true);
		setTimeout(() => {
			setSelectConfirmation(false);
			reset();
		}, 3000); // three seconds for showing the confirmation text
	};

	const searchField = (
		<TextField
			className={classes.textFieldSearch}
			label="Search"
			variant="filled"
			onChange={handleSearchChange}
			value={searchValue}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<SearchIcon className={classes.searchIcon} />
					</InputAdornment>
				),
			}}
			fullWidth
		/>
	);

	const tokenListContent = tokenList.map(item => {
		const { name, symbol, balance, decimals, icon: tokenIcon, address } = item;
		const formatOptions = {
			assetDigits: decimals,
			locale: 'en-US',
		};
		const icon = tokenIcon ?? DefaultLogo;
		const query = searchValue.toLowerCase();
		const stableCoinFound =
			sdk.STABLE_COINS.includes(symbol?.toLowerCase()) && query === 'usd';
		const tokenFound =
			stableCoinFound ||
			name?.toLowerCase().includes(query) ||
			symbol?.toLowerCase().includes(query);
		const hiddenWrappedToken =
			symbol === ALL_CHAINS[destinationChainId]?.wrapped;
		const amount = balance ? formatBN(balance, formatOptions) : 0;
		const showAmount = isMobile ? toFixedDecimals(amount, 6) : amount;
		let balanceProgressIndicator = balance ? (
			showAmount
		) : (
			<CircularProgress className={classes.circularProgress} size={20} />
		);
		if (query && !balance) {
			balanceProgressIndicator = (
				<Tooltip
					title={t('select_token_list.unable_load_balance')}
					placement="top"
				>
					<IconButton>
						<ErrorOutlineIcon className={classes.errorOutlineIcon} />
					</IconButton>
				</Tooltip>
			);
		}

		const tokenSelect = selectedToken(address);

		return (
			<ListItem
				button
				className={clsx(classes.item, {
					[classes.itemActive]: token?.symbol === symbol,
					[classes.showItem]: tokenFound && !hiddenWrappedToken,
					[classes.hiddenItem]: !tokenFound || hiddenWrappedToken,
				})}
				onClick={() => (selectMode ? toggle(address) : handleSelectClick(item))}
				key={`select-${address}`}
			>
				{selectMode ? (
					<Checkbox
						className={classes.checkbox}
						checked={tokenSelect?.selected}
						disabled={tokenSelect?.disabled}
						disableRipple
					/>
				) : null}
				<img
					className={classes.itemIcon}
					src={icon}
					alt={name}
					width={24}
					height={24}
				/>
				<Box className={classes.itemSymbol}>
					{symbol}
					<Box className={classes.itemName}>{name}</Box>
				</Box>
				{(connected && !wrongNetwork && !selectMode) || !assetsFetched ? (
					<Box className={classes.itemAmount}>{balanceProgressIndicator}</Box>
				) : null}
				{selectMode ? (
					<Box className={classes.selectText}>
						{tokenSelect?.selected
							? t('select_token_list.selected')
							: t('select_token_list.add_to_metamask')}
					</Box>
				) : null}
			</ListItem>
		);
	});

	const icon = token?.icon ?? DefaultLogo;
	const name = token?.name;
	const symbol = token?.symbol;
	const query = searchValue.toLowerCase();
	const stableCoinFound = query === 'usd';
	let stableCoinText;

	if (stableCoinFound) {
		stableCoinText = t('select_token_list.stable_coin_usd');
	}

	useEffect(() => {
		if (openMore) {
			handleClose();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openMore]);

	useEffect(() => {
		reset();
		setSelectMode(false);
	}, [open]); // eslint-disable-line react-hooks/exhaustive-deps

	const buttonContent = (
		<Button
			variant="outlined"
			className={classes.button}
			onClick={handleOpen}
			fullWidth
		>
			{name ? (
				<Box component="span" display="flex" alignItems="center">
					<img
						className={classes.buttonIcon}
						src={icon}
						alt={name}
						width={24}
						height={24}
					/>
					<Box className={classes.buttonSymbol} component="span">
						{symbol}
					</Box>
					<Box component="span" className={classes.buttonName}>
						{name}
					</Box>
					<ArrowDropDownIcon className={classes.buttonArrowDown} />
				</Box>
			) : (
				<Box component="span" display="flex" alignItems="center">
					<Box>{t('select_token_list.select_token')}</Box>
					<ArrowDropDownIcon className={classes.buttonArrowDown} />
				</Box>
			)}
		</Button>
	);

	const listContent = (
		<Fade timeout={250} in={open}>
			<Box className={classes.root}>
				<Box className={classes.wrapperContainer}>
					<Box display="flex">
						{selectMode ? (
							<Box className={clsx(classes.headerIcon, classes.backIcon)}>
								<IconButton onClick={() => setSelectMode(false)}>
									<KeyboardBackspaceIcon />
								</IconButton>
							</Box>
						) : null}
						<Box className={classes.title}>
							{selectMode
								? t('select_token_list.add_to_metamask')
								: t('select_token_list.search_token')}
						</Box>
						<Box className={clsx(classes.headerIcon, classes.closeIcon)}>
							<IconButton onClick={handleClose}>
								<CloseIcon />
							</IconButton>
						</Box>
					</Box>
					{searchField}
					<Box
						className={clsx({
							[classes.stableCoin]: stableCoinFound,
						})}
					>
						{stableCoinText}
					</Box>
					<Box className={classes.tokenListContainer}>{tokenListContent}</Box>
				</Box>
				{!selectMode && (
					<Button
						variant="text"
						className={classes.addToMetamaskBox}
						startIcon={<AddCircleOutlineIcon />}
						onClick={() => setSelectMode(!selectMode)}
					>
						{t('select_token_list.add_tokens')}
					</Button>
				)}
				{selectedCount && selectMode ? (
					<Box className={classes.addToMetamaskBox}>
						<Button
							variant="contained"
							className={classes.addSelectedToMetamask}
							onClick={addTokens}
						>
							{selectConfirmation
								? t('select_token_list.waiting_confirmation')
								: t('select_token_list.add_selected_tokens')}
						</Button>
					</Box>
				) : null}
			</Box>
		</Fade>
	);

	return (
		<>
			<Backdrop
				className={classes.backdrop}
				open={open}
				onClick={handleClose}
			/>
			<Box
				className={clsx({
					[classes.wrapper]: open,
				})}
			>
				{buttonContent}
				{listContent}
			</Box>
		</>
	);
};

SelectTokenList.defaultProps = {
	callBackResult: null,
};

SelectTokenList.propTypes = {
	callBackResult: PropTypes.func,
	openMore: PropTypes.bool.isRequired,
};

export default SelectTokenList;

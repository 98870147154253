import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
	Box,
	InputAdornment,
	TextField,
	FormControlLabel,
	Checkbox,
	Link,
	useTheme,
	useMediaQuery,
} from '@energi/ui';
import { formatBN, toBN, getWeb3, isMobile } from '@energi/utils';
import sdk from '@energi/energi-sdk';
import { useMetamask } from '@energi/energi-wallet';
import { GAS_PRICE_MARGIN, GAS_UNIT_AMOUNT } from 'utils/constants';
import Button from 'components/common/Button';
import LinkWithRef from 'components/common/LinkWithRef';
import SelectTokenList from 'components/bridge/SelectTokenList';
import Modal from 'components/common/Modal';
import {
	transferToken,
	approveToken,
	enoughBalance,
	getDepositFee,
} from 'utils/smartcontracts/tokencashier';
import amountToWei from 'utils/amount-to-wei';
import getNumberSeparators from 'utils/get-number-separators';
import tokenListCtx from 'context/tokenlist';
import localTransactionCtx from 'context/localTransaction';
import latestBlockCtx from 'context/latestBlock';
import chainsCtx from 'context/chains';
import modalsCtx from 'context/modals';
import errorCtx from 'context/error';
import useStyles from 'styles/components/bridge/ConvertToken';
import { formatAmountBN, formatBNWithSignificant, useIntl } from 'utils/helper';
import {
	ENV_ENERGI_CHAINID,
	SUPPORTED_NETWORK,
	VALID_CHAINS,
	ALL_CHAINS,
} from 'utils/constants/chains';
import config from 'config/config';

const validateAmount = (token, amount, depositFee, gasFee, nativeCoin) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const t = useIntl();
	const symbol = token?.symbol;
	let message = '';
	if (token && amount && nativeCoin?.balance) {
		const formatBNOptions = {
			decimals: 8,
			assetDigits: token.decimals,
			locale: 'en-US',
		};
		const isCoin = symbol === nativeCoin.symbol;
		const gasFeeBN = toBN(gasFee);
		const nativeCoinBalanceBN = toBN(nativeCoin.balance);
		const feeBN = toBN(depositFee);
		const balanceBN = toBN(token?.balance || '0');
		const bridgeMin = toBN(token?.bridgeMin || '0');
		const bridgeMax = toBN(token?.bridgeMax || '0');
		let amountBN = toBN(amountToWei(amount, Number(token.decimals)));
		if (!message) {
			if (isCoin) {
				amountBN = amountBN.add(feeBN);
			}
			if (amountBN.gt(balanceBN)) {
				message = t('convert_token.amount_higher_available').replace(
					'$',
					symbol,
				);
			}
		}
		if (!message) {
			if (isCoin) {
				amountBN = amountBN.sub(feeBN);
			}
			if (amountBN.lt(bridgeMin)) {
				message = `${t('convert_token.amount_lower_min')} ${formatAmountBN(
					bridgeMin,
					formatBNOptions,
				)} ${symbol}`;
			}
		}
		if (!message) {
			if (isCoin) {
				amountBN = amountBN.add(feeBN);
			}
			if (amountBN.gt(bridgeMax)) {
				message = `${t('convert_token.amount_higher_max')} ${formatAmountBN(
					bridgeMax,
					formatBNOptions,
				)} ${symbol}`;
			}
		}
		if (nativeCoinBalanceBN.lt(feeBN.add(gasFeeBN)) && !isCoin && amount) {
			message = t('convert_token.insufficient_fee_balance').replace(
				'$',
				nativeCoin.symbol,
			);
		}
		if (amount === '0' && token?.balance === '0') {
			message = `${t('convert_token.amount_lower_min')} ${formatAmountBN(
				bridgeMin,
				formatBNOptions,
			)} ${symbol}`;
		}
	}
	return message;
};

const ConvertToken = ({ callBackTransferToken, openMore }) => {
	const { chainId, address, sendTx, connected } = useMetamask();
	const [depositFee, setDepositFee] = useState(0);
	const classes = useStyles();
	const t = useIntl();
	const theme = useTheme();
	const smallScreenSize = useMediaQuery(theme.breakpoints.up('sm'));
	const [checkConfirm, setCheckConfirm] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [amount, setAmount] = useState('');
	const [approvals, setApprovals] = useState({});
	const [gasFee, setGasFee] = useState(0);
	const [gweiAmount, setGweiAmount] = useState(0);

	const { sourceChainId, destinationChainId } = useContext(chainsCtx);
	const { token, setTokenId, checkTokenApproval, getNativeCoin } =
		useContext(tokenListCtx);
	const [localTxn, setLocalTxn] = useContext(localTransactionCtx);
	const { connectToMetamask } = useContext(modalsCtx);
	const { latestBlock } = useContext(latestBlockCtx);
	const { setErrorMessage } = useContext(errorCtx);

	const symbol = token?.symbol;
	const isCoin = token?.address === sdk.ZERO_ADDRESS;
	const tokenAddress = token?.address;

	// issue with small amount of difference for transfering, which happens with metamask mobile
	const METAMASK_MOBILE_AMOUNT_DIFFERENCE = '10000000000';
	const nativeCoin = VALID_CHAINS.includes(chainId) && getNativeCoin(chainId);

	const wrongAmountMessage = validateAmount(
		token,
		amount,
		depositFee,
		gasFee,
		nativeCoin,
	);
	const validAmount = !wrongAmountMessage;

	let allwanceBalance = token?.allowance;
	if (token?.allowance === '0x0') {
		allwanceBalance = 0;
	}
	const enoughApprovedBalance = enoughBalance(
		allwanceBalance,
		amountToWei(amount, token?.decimals),
	);

	const isApproved = isCoin || enoughApprovedBalance;

	if (isApproved) {
		delete approvals[token?.id];
	}

	const formatBNOptions = {
		assetDigits: nativeCoin?.decimals ?? 18,
		decimals: 8,
		locale: 'en-US',
	};

	const wrongNetwork = !VALID_CHAINS.includes(chainId);
	const canProceed = token && checkConfirm && validAmount;
	let balance;
	let bridgeMax;
	let radioButtonsContent;
	let validateAmountContent;

	useEffect(() => {
		if (VALID_CHAINS.includes(chainId)) {
			const fetchFees = async () => {
				if (chainId) {
					getDepositFee(chainId, address).then(fee => {
						setDepositFee(fee);
					});
					// Getting the latest gas price + adding a margin to it
					const gasWeiAmount = await getWeb3(chainId).eth.getGasPrice();
					const withMargin = toBN(gasWeiAmount)
						.mul(toBN(100 + GAS_PRICE_MARGIN))
						.div(toBN(100))
						.toString();
					setGweiAmount(withMargin);
					setGasFee(toBN(withMargin).mul(toBN(GAS_UNIT_AMOUNT)).toString());
				}
			};
			fetchFees();
			setAmount('');
			setCheckConfirm(false);
			setApprovals({});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chainId, address]);

	useEffect(() => {
		if (
			token?.id &&
			approvals[token?.id] &&
			connected &&
			!wrongNetwork &&
			connected
		) {
			checkTokenApproval(token?.id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [latestBlock[sourceChainId]]);

	if (typeof token?.balance === 'string') {
		balance = toBN(token.balance);
	}
	if (typeof token?.bridgeMax === 'string') {
		bridgeMax = toBN(token.bridgeMax);
	}

	// Calculates the max amount considering balance, deposit fee and gas fee
	const handleMaxAmountClick = async () => {
		// Calculation throws error if the balance undefined because of toBN function
		if (!balance || !bridgeMax) {
			return;
		}

		const feeBN = toBN(depositFee);
		const totalGasFeeBN = toBN(gasFee);

		let maxAmountBn = balance;
		if (nativeCoin.symbol === token?.symbol) {
			maxAmountBn = balance.sub(feeBN).sub(totalGasFeeBN);
			// an exception that happens on energi network on metamask mobile
			if (isMobile && chainId === ENV_ENERGI_CHAINID) {
				maxAmountBn = maxAmountBn.sub(toBN(METAMASK_MOBILE_AMOUNT_DIFFERENCE));
			}
		}

		const maxAmount = formatAmountBN(maxAmountBn, {
			assetDigits: token?.decimals,
			decimals: token?.decimals,
			locale: 'en-US',
		});

		setAmount(maxAmountBn.gt(toBN(0)) ? maxAmount : '0');
	};

	const handleCheckBoxChange = event => {
		setCheckConfirm(event.target.checked);
	};

	const handleTransferToken = async () => {
		let trimmedAmount = amount;
		if (amount.includes('.')) {
			const [leading, trailing] = trimmedAmount.split('.');
			trimmedAmount = `${Number(leading)}.${trailing.replace(/0+$/, '')}`;
		}
		try {
			const transferTxn = await transferToken(
				chainId,
				isCoin ? sdk.ZERO_ADDRESS : tokenAddress,
				address,
				amountToWei(trimmedAmount, Number(token?.decimals)),
				sendTx,
				gweiAmount,
			);
			if (address) {
				setLocalTxn({
					...localTxn,
					[address]: [
						...(localTxn[address] ?? []),
						{
							txHash: transferTxn,
							token: tokenAddress,
							amount: trimmedAmount,
							decimals: String(token?.decimals),
							sourceStatus: 'pending',
							fee: formatBN(depositFee, formatBNOptions).replaceAll(
								getNumberSeparators.groupSeparator,
								',',
							),
							timestamp: Math.floor(Date.now() / 1000),
							source: SUPPORTED_NETWORK[chainId],
							destination: SUPPORTED_NETWORK[destinationChainId],
						},
					],
				});
			}
			setAmount('');
			setCheckConfirm(false);
			if (callBackTransferToken) {
				callBackTransferToken({
					...token,
					[ALL_CHAINS[chainId].transferTxField]: transferTxn,
					sourceChainId: chainId,
					destinationChainId,
					amount: trimmedAmount,
					symbol,
				});
			}
		} catch (error) {
			if (error?.message === 'Transaction rejected') {
				setErrorMessage(error.message);
			}
		}
	};

	const handleApproveToken = async () => {
		try {
			const approvalTx = await approveToken(
				chainId,
				tokenAddress,
				address,
				// maximum allowance in wei = 2**256 - 1
				// will cause overflow for tokens not complying to ERC20 standard of using uint256 for allowance
				'115792089237316195423570985008687907853269984665640564039457',
				sendTx,
			);
			setApprovals({
				...approvals,
				[token.id]: approvalTx,
			});
		} catch (error) {
			if (error?.message === 'Transaction rejected') {
				setErrorMessage(error.message);
			}
		}
	};

	const handleShowModal = () => {
		setOpenModal(true);
		setCheckConfirm(true);
	};

	const handleHideModal = () => {
		setOpenModal(false);
	};

	const handleCallBackResult = selectedToken => {
		setTokenId(selectedToken.id);
		setAmount('');
	};

	const handleChangeAmount = e => {
		let value = e.target.value.trim();
		const splittedValue = value.split('.');
		if (splittedValue[1]) {
			splittedValue[1] = splittedValue[1].substr(0, token?.decimals);
			value = splittedValue.join('.');
		}
		value = value.replace(/,/g, '.');
		if (value.match(/^[0-9]*\.?[0-9]*$/)) {
			setAmount(value);
		}
	};

	const handleBlurAmount = () => {
		if (typeof amount === 'string' && amount.endsWith('.')) {
			setAmount(amount.substr(0, amount.length - 1));
		}
	};

	const maxButtonContent = (
		<Box className={classes.maxButtonWrap}>
			<Button
				className="textFieldAmount_max"
				disabled={!token}
				variant="contained"
				color="primary"
				onClick={handleMaxAmountClick}
				disableElevation
			>
				{t('convert_token.button_max')}
			</Button>
		</Box>
	);

	const textFieldAmount = (
		<Box mt={1.5} lang="en-US">
			<TextField
				error={!validAmount}
				helperText={wrongAmountMessage}
				label={t('convert_token.amount_label')}
				disabled={!token}
				placeholder="0.0"
				variant="filled"
				value={amount}
				onBlur={handleBlurAmount}
				onChange={handleChangeAmount}
				className={classes.textFieldAmount}
				fullWidth
				InputProps={{
					disableUnderline: true,
					inputMode: 'decimal',
					lang: 'en-US',
					endAdornment: connected && !wrongNetwork && (
						<InputAdornment position="end">
							<Box display="flex" flexDirection="column">
								<Box className="textFieldAmount_balance">
									{token && t('convert_token.balance')}{' '}
									{balance
										? formatBNWithSignificant(balance, {
												assetDigits: token?.decimals,
												decimals: token?.decimals,
												locale: 'en-US',
												significant: 6,
										  })
										: 0}{' '}
									{symbol}
								</Box>
								{connected && !wrongNetwork ? maxButtonContent : null}
							</Box>
						</InputAdornment>
					),
				}}
				InputLabelProps={{
					classes: {
						root: 'textFieldAmount_label',
						focused: 'textFieldAmount_label-focused',
					},
					shrink: true,
				}}
			/>
		</Box>
	);

	const feeContent = (
		<Box
			display="flex"
			justifyContent="space-between"
			whiteSpace="nowrap"
			mt={1}
		>
			<Box component="small" className={classes.fee}>
				{t('convert_token.fee')}
			</Box>
			<Box component="small" className={classes.feeAmount}>
				{depositFee
					? formatBN(depositFee, formatBNOptions).replaceAll(',', '.')
					: 0}{' '}
				{nativeCoin?.symbol}
			</Box>
		</Box>
	);

	const checkboxConfirm = (
		<Box my={3}>
			<FormControlLabel
				className={classes.checkBoxLabel}
				control={
					<Checkbox
						className={classes.checkboxConfirm}
						disabled={!token}
						checked={checkConfirm}
						onChange={handleCheckBoxChange}
					/>
				}
				label={
					<Box ml={1}>
						{t('convert_token.confirmation')}{' '}
						<Button
							component={Link}
							className={classes.learnMore}
							onClick={handleShowModal}
							variant="text"
							color="primary"
							disableElevation
							disableFocusRipple
							disableRipple
						>
							{t('convert_token.learn_more')}
						</Button>
					</Box>
				}
			/>
		</Box>
	);

	const approveTokenContent = (
		<Box className={classes.approveTokenText}>
			{t('convert_token.approvalMetaMask', {
				values: { tokenText: symbol },
			})}
		</Box>
	);

	const feeNRGCheckboxContent = (
		<>
			{feeContent}
			{checkboxConfirm}
		</>
	);

	let bridgeButtonCb;
	let bridgeButtonClass;
	let bridgeButtonDisabled;
	let bridgeButtonLabel;
	let approvedValidAmountContent =
		connected && !wrongNetwork ? feeNRGCheckboxContent : null;
	if (!isApproved && validAmount && amount) {
		approvedValidAmountContent =
			connected && !wrongNetwork ? approveTokenContent : null;
		bridgeButtonCb = handleApproveToken;
		const approvalTx = approvals[token?.id];
		bridgeButtonLabel =
			approvalTx && !isApproved
				? t('convert_token.approving')
				: t('convert_token.approve');
		if (approvalTx) {
			bridgeButtonClass = classes.approvingNetworkButton;
			approvedValidAmountContent =
				connected && !wrongNetwork ? approveTokenContent : null;
			bridgeButtonDisabled = true;
		} else {
			// bridgeButtonDisabled = !canProceed;
			bridgeButtonDisabled = false;
		}
	} else {
		bridgeButtonCb = handleTransferToken;
		bridgeButtonLabel = t('convert_token.transfer');
		if (symbol) {
			bridgeButtonLabel += ` ${symbol} `;
		}
		if (smallScreenSize) {
			bridgeButtonLabel += ` ${t(
				`convert_token.${SUPPORTED_NETWORK[destinationChainId]}_blockchain`,
			)}`;
		} else {
			bridgeButtonLabel += ` ${t(
				`convert_token.${SUPPORTED_NETWORK[destinationChainId]}_without_blockchain`,
			)}`;
		}
		bridgeButtonDisabled = !canProceed;
		if (amount === '') {
			bridgeButtonDisabled = true;
		}
		if (Number.isNaN(Number(amount)) || amount === '0') {
			bridgeButtonDisabled = true;
		}
	}

	const buttonContent =
		connected && !wrongNetwork ? (
			<Box mt={2}>
				<Button
					className={clsx(classes.blockchainButton, bridgeButtonClass)}
					disabled={bridgeButtonDisabled}
					variant="contained"
					color="primary"
					size="large"
					onClick={bridgeButtonCb}
					fullWidth
				>
					{bridgeButtonLabel}
				</Button>
			</Box>
		) : (
			<Box mt={2}>
				<Button
					className={classes.blockchainButton}
					variant="contained"
					color="primary"
					size="large"
					fullWidth
					onClick={connectToMetamask}
				>
					{t('metamask_box.connect_metamask')}
				</Button>
			</Box>
		);

	const modalContent = (
		<Modal
			show={openModal}
			handleClose={handleHideModal}
			button={[
				{
					variant: 'contained',
					color: 'primary',
					size: 'large',
					fullWidth: true,
					text: 'I understand',
					onClick: handleHideModal,
				},
			]}
		>
			<Box
				component="h2"
				fontSize={{ xs: 30, sm: 34 }}
				fontWeight={600}
				lineHeight={1.5}
				mb={3.5}
			>
				{t('convert_token.waiting_period_title')}
			</Box>
			<Box component="p" m={0}>
				{t('convert_token.waiting_period_text')}
			</Box>
			<Box
				component="h2"
				fontSize={{ xs: 30, sm: 34 }}
				fontWeight={600}
				lineHeight={1.5}
				my={3.5}
			>
				{t('convert_token.why_waiting_period_title')}
			</Box>
			<Box component="p" mb={2.5}>
				{t('convert_token.why_waiting_period_text_1')}
			</Box>
			<Box component="p" mb={7}>
				{t('convert_token.why_waiting_period_text_2')}{' '}
				<Link
					component={LinkWithRef}
					to={config.NEXUS_URL}
					target="_blank"
					rel="noopener noreferrer"
					underline="always"
				>
					{t('convert_token.why_waiting_period_text_3')}
				</Link>{' '}
				{t('convert_token.why_waiting_period_text_4')}
			</Box>
		</Modal>
	);

	const selectTokens = (
		<Box mb={1.5}>
			<SelectTokenList
				callBackResult={handleCallBackResult}
				openMore={openMore}
			/>
		</Box>
	);

	return (
		<Box position="relative">
			<Box className={classes.container}>
				{selectTokens}
				{radioButtonsContent}
				{textFieldAmount}
				{approvedValidAmountContent}
				{buttonContent}
			</Box>
			{modalContent}
			{validateAmountContent}
		</Box>
	);
};

ConvertToken.defaultProps = {
	callBackTransferToken: null,
};

ConvertToken.propTypes = {
	callBackTransferToken: PropTypes.func,
	openMore: PropTypes.bool.isRequired,
};

export default ConvertToken;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby-plugin-intl';

const LinkWithRef = React.forwardRef(({ children, ...props }, ref) => (
	<Link {...props} innerRef={ref}>
		{children}
	</Link>
));

LinkWithRef.propTypes = {
	children: PropTypes.any.isRequired,
};

export default LinkWithRef;

import React, { useState } from 'react';
import { Button } from '@energi/ui';
import useStyles from 'styles/components/common/LowBalanceBanner';
import WarningIcon from 'assets/images/report-problem.svg';
import config from 'config/config';
import { useIntl } from 'utils/helper';

const LowBalanceBanner = () => {
	const classes = useStyles();
	const t = useIntl();
	const [isHidden, setIsHidden] = useState(false);

	if (!isHidden) {
		return (
			<div className={classes.root}>
				<div className={classes.item}>
					<WarningIcon className={classes.icon} fontSize="inherit" />
					{t('low_balance_banner.text')}
				</div>
				<div className={classes.menu}>
					<ul>
						<li>
							<Button
								aria-label="close"
								color="inherit"
								onClick={() => setIsHidden(true)}
							>
								{t('low_balance_banner.dismiss_button')}
							</Button>
						</li>
						<li>
							<Button
								href={config.EXCHANGES_URL}
								target="_blank"
								rel="noopener noreferrer"
							>
								{t('low_balance_banner.add_balance_button')}
							</Button>
						</li>
					</ul>
				</div>
			</div>
		);
	}
	return null;
};

export default LowBalanceBanner;

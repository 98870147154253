import { useEffect, useState } from 'react';

const useActivityChecker = (minute = 5) => {
	const [idle, setIdle] = useState(false);
	const events = [
		'onclick',
		'onmousemove',
		'onscroll',
		'onkeydown',
		'ontouchmove',
	];

	const clearEvents = () => {
		events.forEach(e => {
			window[e] = () => {};
		});
	};

	useEffect(() => {
		let counter = 0;
		const reset = () => {
			counter = 0;
			setIdle(false);
		};

		events.forEach(e => {
			window[e] = reset;
		});

		const timer = setInterval(() => {
			counter += 1;
			if (counter >= minute * 60) {
				clearInterval(timer);
				setIdle(true);
			}
		}, 1000);
		return () => {
			clearInterval(timer);
			clearEvents();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return idle;
};

export default useActivityChecker;

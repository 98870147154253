import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Button as MuiButton } from '@energi/ui';
import useStyles from 'styles/components/common/Button';

const Button = props => {
	const {
		color,
		textTransform,
		variant,
		disabled,
		children,
		className,
		classDef,
		...other
	} = props;
	const classes = useStyles(props);

	return (
		<MuiButton
			className={clsx(classes.root, className, classes[classDef])}
			variant={variant}
			disabled={disabled}
			{...other}
		>
			{children}
		</MuiButton>
	);
};

Button.defaultProps = {
	color: 'default',
	variant: 'text',
	className: '',
	disabled: false,
	textTransform: 'uppercase',
	classDef: null,
};

Button.propTypes = {
	color: PropTypes.oneOf([
		'default',
		'primary',
		'secondary',
		'error',
		'warning',
		'info',
		'success',
	]),
	className: PropTypes.string,
	variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
	disabled: PropTypes.bool,
	textTransform: PropTypes.string,
	classDef: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.element,
		PropTypes.string,
	]).isRequired,
};

export default Button;

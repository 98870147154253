import { makeStyles, alpha } from '@energi/ui';
import _ from 'lodash';

export default makeStyles(theme => ({
	root: ({ color, variant, disabled, textTransform }) => {
		if (_.has(theme.palette, color)) {
			let textColor =
				variant === 'contained'
					? theme.palette.grey[1000]
					: theme.palette[color].main;
			if (disabled) {
				textColor = alpha(theme.palette.common.white, 0.32);
			}

			let backgroundColor =
				variant === 'contained' && theme.palette[color].main;
			if (disabled) {
				backgroundColor = alpha(theme.palette.common.white, 0.32);
			}

			let borderColor =
				variant === 'outlined' && alpha(theme.palette[color].dark, 0.5);
			if (disabled && variant === 'outlined') {
				borderColor = alpha(theme.palette.common.black, 0.7);
			}

			const transform = textTransform === 'none' && 'none';

			return {
				letterSpacing: 1.25,
				color: textColor,
				backgroundColor,
				borderColor,
				textTransform: transform,
				'&.MuiButton-sizeLarge': {
					padding: theme.spacing(1.375, 2.75),
				},
				'&:hover': {
					backgroundColor: alpha(theme.palette[color].main, 0.8),
					boxShadow:
						'0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);',
				},
				'&.Mui-disabled': {
					backgroundColor,
				},
			};
		}

		return {};
	},
}));

import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
	Box,
	IconButton,
	Backdrop,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Divider,
	Drawer,
	Link,
	useTheme,
	useMediaQuery,
} from '@energi/ui';
import sdk from '@energi/energi-sdk';
import {
	Menu as MenuIcon,
	ContactSupportOutlined as FAQIcon,
	SwapHoriz as SwapHorizIcon,
	BarChart as BarChartIcon,
	Twitter as TwitterIcon,
	BugReport as BugReportIcon,
	Feedback as FeedbackIcon,
} from '@energi/ui/icons';
import EnergiLogo from 'assets/images/logo.svg';
import EnergiSwapIcon from 'assets/images/energiswap.svg';
import SupportIcon from 'assets/images/support.svg';
import DiscordIcon from 'assets/images/discord.svg';
import useStyles from 'styles/components/common/LeftSidebar';
import config from 'config/config';
import { useIntl } from 'utils/helper';

const { ENERGISWAP_URL, ANALYTICS_URL, BOUNTY_URL } = config;

const LeftSideBar = ({
	version,
	pageContext,
	callbackClickButton,
	tabOpen,
	hideBookmark,
}) => {
	const t = useIntl();
	const classes = useStyles();
	const theme = useTheme();
	const largeScreenSize = useMediaQuery(theme.breakpoints.up('lg'));
	const smallScreenSize = useMediaQuery(theme.breakpoints.down('sm'));
	const propsVersion = version || pageContext.version;
	const DIVIDER = 5;
	const MENU = [];

	const [open, setOpen] = useState(false);
	let energiLogo;

	const handleBackdropClose = () => {
		setOpen(false);
	};

	const handleDrawer = () => {
		setOpen(!open);
	};

	if (open || smallScreenSize || largeScreenSize) {
		energiLogo = (
			<EnergiLogo
				className={clsx(classes.energiLogo, {
					[classes.energiLogoOpen]: open,
				})}
			/>
		);
	}

	if (smallScreenSize) {
		energiLogo = null;
	} else {
		MENU.push(
			{
				name: t('layout.left_sidebar.nav.bridge'),
				active: 'true',
				icon: <SwapHorizIcon />,
			},
			{
				name: t('layout.left_sidebar.nav.energiswap'),
				href: ENERGISWAP_URL,
				target: '_blank',
				rel: 'noreferrer',
				icon: <EnergiSwapIcon />,
			},
			{
				name: t('layout.left_sidebar.nav.analytics'),
				href: ANALYTICS_URL,
				target: '_blank',
				rel: 'noreferrer',
				icon: <BarChartIcon />,
			},
		);
	}

	MENU.push(
		{
			name: t('layout.left_sidebar.nav.support'),
			href: 'https://wiki.energi.world/en/guides/energi-bridge-quick-start',
			target: '_blank',
			rel: 'noopener noreferrer',
			icon: <SupportIcon />,
		},
		{
			name: t('layout.left_sidebar.nav.faq'),
			href: 'https://wiki.energi.world/en/faq/energi-bridge-faq',
			target: '_blank',
			rel: 'noopener noreferrer',
			icon: <FAQIcon />,
		},
	);

	if (largeScreenSize || open) {
		MENU.push(
			{
				name: 'Report Bug',
				href: BOUNTY_URL,
				target: '_blank',
				rel: 'noopener noreferrer',
				icon: <BugReportIcon />,
			},
			{
				name: 'Send Feedback',
				onClick: () => callbackClickButton('send_feedback'),
				icon: <FeedbackIcon />,
			},
		);
	}

	const toolbarContent = (
		<div
			className={clsx({
				[classes.toolbar]: hideBookmark,
				[classes.toolbarBookmark]: !hideBookmark,
			})}
		>
			<IconButton
				color="inherit"
				aria-label="open drawer"
				onClick={handleDrawer}
				className={clsx(classes.menuButton, {
					[classes.menuButtonOpen]: open,
				})}
			>
				<MenuIcon />
			</IconButton>
			{energiLogo}
		</div>
	);

	useEffect(() => {
		setOpen(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!smallScreenSize]);

	useEffect(() => {
		if (tabOpen) {
			setOpen(true);
		} else {
			setOpen(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tabOpen]);

	return (
		<>
			<Backdrop
				className={classes.backdrop}
				open={open}
				onClick={handleBackdropClose}
			/>
			<Drawer
				variant="permanent"
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				})}
				classes={{
					paper: clsx(classes.paperRoot, {
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					}),
				}}
			>
				{toolbarContent}
				<List component="div">
					{MENU.map((nav, index) => (
						<Fragment key={nav.name}>
							{index === DIVIDER && <Divider className={classes.divider} />}
							<ListItem
								component={Link}
								className={clsx(classes.rootList, {
									[classes.rootListOpen]: open,
								})}
								{...nav}
								button
							>
								<ListItemIcon
									className={clsx(classes.listItemIcon, {
										[classes.listItemIconOpen]: open,
										[classes.listItemIconActive]: nav.active,
									})}
								>
									{nav.icon}
								</ListItemIcon>
								<ListItemText
									className={clsx(classes.listItemText, {
										[classes.listItemTextOpen]: open,
										[classes.listItemTextActive]: nav.active,
									})}
									primary={nav.name}
								/>
							</ListItem>
						</Fragment>
					))}
				</List>
				<Box
					className={clsx(classes.communitySocialIconsWrap, {
						[classes.communitySocialIconsWrapOpen]: open,
					})}
				>
					<Box component="p" className={classes.community}>
						{t('layout.left_sidebar.community')}
					</Box>
					<Box display="flex" mb={2}>
						<Box>
							<a href={sdk.social.TWITTER_URL} target="_blank" rel="noreferrer">
								<TwitterIcon className={classes.socialIcon} />
							</a>
						</Box>
						<Box ml={2}>
							<a href={sdk.social.DISCORD_URL} target="_blank" rel="noreferrer">
								<DiscordIcon className={classes.socialIcon} />
							</a>
						</Box>
					</Box>
					<Box component="p" className={classes.version}>
						{t('layout.left_sidebar.version')} {propsVersion}
						<br />
						{t('layout.left_sidebar.energi_cryptocurrency')}
					</Box>
				</Box>
				<Box
					className={clsx(classes.versionShort, {
						[classes.versionShortClose]: open,
					})}
				>
					<Box component="p" fontSize={14}>
						{t('layout.left_sidebar.version_short')}
						{propsVersion}
					</Box>
				</Box>
			</Drawer>
		</>
	);
};

LeftSideBar.defaultProps = {
	version: '',
	callbackClickButton: null,
	tabOpen: false,
	hideBookmark: false,
};

LeftSideBar.propTypes = {
	pageContext: PropTypes.shape({
		version: PropTypes.string.isRequired,
	}).isRequired,
	version: PropTypes.string,
	callbackClickButton: PropTypes.func,
	tabOpen: PropTypes.bool,
	hideBookmark: PropTypes.bool,
};

export default LeftSideBar;

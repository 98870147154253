import { makeStyles, alpha } from '@energi/ui';

export default makeStyles(theme => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		position: 'relative',
		background: `linear-gradient(0deg, ${alpha(
			theme.palette.common.white,
			0.16,
		)}, ${alpha(theme.palette.common.white, 0.16)}), ${
			theme.palette.grey[1000]
		};`,
		borderRadius: 6,
		maxWidth: 520,
		boxShadow: theme.shadows[5],
		margin: theme.spacing(0, 2),
		outline: 0,
		overflowY: 'auto',
		'-webkitOverflowScrolling': 'touch',
		maxHeight: '100%',
		[theme.breakpoints.up('sm')]: {
			overflowY: 'hidden',
		},
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(2.5, 3),
		color: theme.palette.grey[1000],
		borderTopLeftRadius: 6,
		borderTopRightRadius: 6,
		backgroundColor: theme.palette.orange[400],
	},
	title: {
		fontSize: 20,
		fontWeight: 600,
		margin: theme.spacing('auto', 0),
	},
	icon: { marginRight: theme.spacing(2) },
	content: {
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(3),
		lineHeight: '24px',
	},
	warningText: {
		display: 'inline',
		marginRight: theme.spacing(0.5),
		color: theme.palette.orange[400],
		textTransform: 'uppercase',
	},
	description: {
		fontSize: 16,
		fontWeight: 600,
	},
	line: {
		display: 'block',
		width: '100%',
		height: 1,
		backgroundColor: theme.palette.grey[100],
		margin: theme.spacing(3, 0),
	},
	checkbox: {
		'& .MuiCheckbox-root': {
			marginRight: theme.spacing(1),
		},
		'& .Mui-checked': {
			color: theme.palette.text.primary,
		},
	},
	buttonWrap: {
		margin: theme.spacing(3, 'auto', 0, 'auto'),
	},
	button: {
		color: theme.palette.grey[1000],
		backgroundColor: theme.palette.orange[400],
		'&:hover': {
			backgroundColor: alpha(theme.palette.orange[400], 0.8),
		},
	},
	backdrop: {
		backgroundColor: `${alpha(theme.palette.grey[1000], 0.8)}!important`,
	},
}));

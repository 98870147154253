import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@energi/ui';
import { Close as CloseIcon } from '@energi/ui/icons';
import WarningIcon from 'assets/images/report-problem.svg';
import useStyles from 'styles/components/common/BookmarkModal';

const BookmarkModal = ({ hideBookmark, setHideBookmark, url }) => {
	const classes = useStyles();

	const handleClose = () => {
		setHideBookmark(true);
	};

	if (!hideBookmark) {
		return (
			<div className={classes.root}>
				<div className={classes.item}>
					<WarningIcon className={classes.warning} fontSize="inherit" />
					<div>
						Always make sure the URL is <strong>{url}</strong> - bookmark it to
						be safe.
					</div>
				</div>
				<div className={classes.item}>
					<IconButton
						aria-label="close"
						color="inherit"
						size="small"
						onClick={handleClose}
					>
						<CloseIcon fontSize="inherit" />
					</IconButton>
				</div>
			</div>
		);
	}
	return null;
};

BookmarkModal.defaultProps = {
	hideBookmark: false,
};

BookmarkModal.propTypes = {
	hideBookmark: PropTypes.bool,
	setHideBookmark: PropTypes.func.isRequired,
	url: PropTypes.string.isRequired,
};

export default BookmarkModal;

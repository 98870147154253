import { makeStyles, alpha } from '@energi/ui';

export default makeStyles(theme => ({
	fromBox: {
		position: 'relative',
		width: '100%',
		height: 89,
		borderRadius: 10,
		overflow: 'hidden',
		boxShadow: `0px 9px 12px ${alpha(
			theme.palette.common.black,
			0.14,
		)}, 0px 3px 16px ${alpha(
			theme.palette.common.black,
			0.12,
		)}, 0px 5px 6px ${alpha(theme.palette.common.black, 0.2)};`,
		[theme.breakpoints.up('sm')]: {
			height: 154,
			borderRadius: 6,
		},
	},
	toBox: {
		position: 'relative',
		width: '100%',
		height: 89,
		borderRadius: 10,
		overflow: 'hidden',
		boxShadow: `0px 9px 12px ${alpha(
			theme.palette.common.black,
			0.14,
		)}, 0px 3px 16px ${alpha(
			theme.palette.common.black,
			0.12,
		)}, 0px 5px 6px ${alpha(theme.palette.common.black, 0.2)};`,
		[theme.breakpoints.up('sm')]: {
			height: 154,
			borderRadius: 6,
		},
	},
	energiBox: {
		backgroundColor: theme.palette.green[500],
	},
	ethereumBox: {
		backgroundColor: theme.palette.grey[800],
	},
	swapButtonWrap: {
		position: 'relative',
		zIndex: 2,
		padding: theme.spacing(1),
		borderRadius: 6,
		margin: theme.spacing('auto', -2.2),
		backgroundColor: '#18191A',
		transform: 'rotate(45deg)',
		[theme.breakpoints.up('sm')]: {
			margin: theme.spacing('auto', -3.5),
			padding: theme.spacing(1.5),
		},
	},
	swapHidden: {
		visiblity: 'hidden',
	},
	swapBox: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: 35,
		height: 35,
		borderRadius: 6,
		backgroundColor: theme.palette.common.white,
		boxShadow: `0px 9px 12px ${alpha(
			theme.palette.common.black,
			0.14,
		)}, 0px 3px 16px ${alpha(
			theme.palette.common.black,
			0.12,
		)}, 0px 5px 6px ${alpha(theme.palette.common.black, 0.2)};`,
		'& .swapHorizIcon': {
			transform: 'rotate(-45deg)',
		},
		'&:hover': {
			background: `linear-gradient(0deg, ${alpha(
				theme.palette.primary.main,
				0.08,
			)}, ${alpha(theme.palette.primary.main, 0.08)}), #FFFFFF;`,
			'& .swapHorizIcon': {
				color: theme.palette.green[900],
			},
		},
		[theme.breakpoints.up('sm')]: { width: 45, height: 45 },
	},
	swapIconButton: {
		color: theme.palette.common.black,
	},
	sourceChainLogo: {
		position: 'absolute',
		right: 'auto',
		top: -38,
		left: -129,
		zIndex: 2,
		width: 310,
		height: 310,
		[theme.breakpoints.up('sm')]: { top: -80, left: -108, height: 310 },
	},
	assetIcon: {
		width: 32,
		height: 32,
	},
	destinationChainLogo: {
		position: 'absolute',
		left: 'auto',
		top: -38,
		right: -129,
		zIndex: 2,
		width: 310,
		height: 310,
		[theme.breakpoints.up('sm')]: { top: -80, right: -108, height: 310 },
	},
	ethereumAssetName: {
		fontSize: 14,
		fontWeight: 600,
		marginLeft: theme.spacing(0.5),
		textTransform: 'uppercase',
		color: theme.palette.common.white,
		[theme.breakpoints.up('sm')]: {
			fontSize: 24,
			marginLeft: theme.spacing(2),
		},
		[theme.breakpoints.up('lg')]: {
			fontSize: 34,
		},
	},
	energiAssetName: {
		fontSize: 14,
		fontWeight: 600,
		textTransform: 'uppercase',
		color: theme.palette.grey[800],
		marginLeft: theme.spacing(0.5),
		[theme.breakpoints.up('sm')]: {
			fontSize: 24,
			marginLeft: theme.spacing(2),
		},
		[theme.breakpoints.up('lg')]: {
			fontSize: 34,
		},
	},
	ethereumText: {
		position: 'relative',
		top: 8,
		fontSize: 14,
		fontWeight: 600,
		textTransform: 'uppercase',
		color: alpha(theme.palette.common.white, 0.7),
		[theme.breakpoints.up('sm')]: {
			fontSize: 20,
			top: 0,
		},
	},
	energiText: {
		position: 'relative',
		top: 8,
		fontSize: 14,
		fontWeight: 600,
		textTransform: 'uppercase',
		color: alpha(theme.palette.grey[800], 0.6),
		[theme.breakpoints.up('sm')]: {
			fontSize: 20,
			top: 0,
		},
	},
	iconNameWrap: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		marginTop: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			top: 0,
			marginTop: theme.spacing(4),
		},
	},
}));

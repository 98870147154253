import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
	Box,
	Button,
	CircularProgress,
	Typography,
	Skeleton,
} from '@energi/ui';
import { AccountBalanceWallet as AccountBalanceWalletIcon } from '@energi/ui/icons';
import { useMetamask } from '@energi/energi-wallet';
import HistoryItem from 'components/bridge/HistoryItem';
import useStyles from 'styles/components/bridge/BridgeHistory';
import transactionCtx from 'context/transactions';
import localTransactionCtx from 'context/localTransaction';
import modalsCtx from 'context/modals';
import { useIntl } from 'utils/helper';
import { TRANSACTIONS_PER_PAGE } from 'utils/constants';

const BridgeHistory = React.memo(({ wrongNetwork, handleShowTransaction }) => {
	const classes = useStyles();
	const t = useIntl();
	const { transactions, loading, loadTx } = useContext(transactionCtx);
	const [localTxn] = useContext(localTransactionCtx);
	const { connectToMetamask } = useContext(modalsCtx);
	const { address, connected } = useMetamask();
	const [loadMoreTx, setLoadMoreTx] = useState(false);
	const [page, setPage] = useState(1); // default is the first page
	const txsPerPage = TRANSACTIONS_PER_PAGE;

	const title = (
		<Box className={classes.sectionTitle} component="p">
			{t('bridge_history.title')}
		</Box>
	);

	const localTransactions = localTxn[address];
	const depositsArray = useMemo(() => {
		const results = localTxn[address]?.length
			? localTxn[address].filter(
					({ txHash: id1 }) =>
						!transactions.some(({ txHash: id2 }) => id2 === id1),
			  )
			: [];

		let deposits;
		if (results.length > 0) {
			deposits = [...results, ...transactions];
		} else {
			localStorage.setItem('new-transactions', JSON.stringify([]));
			deposits = transactions;
		}

		const sortedDeposits = deposits.sort((a, b) => {
			return b.timestamp - a.timestamp;
		});

		const depositTxns =
			sortedDeposits &&
			deposits.reduce((accumulator, txn) => {
				const date = new Date(txn.timestamp * 1000).toLocaleDateString('en-GB');
				if (accumulator[date]) {
					accumulator[date] = [...accumulator[date], { ...txn }];
				} else {
					accumulator[date] = [{ ...txn }];
				}
				return accumulator;
			}, {});

		return Object.entries(depositTxns).map(e => ({
			[e[0]]: e[1],
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [transactions, localTransactions]);

	const History = useMemo(() => {
		return (
			<Box className={classes.txHistoryContainer}>
				{depositsArray.map(txn => {
					const splitTxn = Object.entries(txn)[0];
					const date = splitTxn[0];
					const txnArray = splitTxn[1];
					return (
						<Box className={classes.transactionsRange} key={date}>
							{txnArray.map(txn => {
								if (txn?.amount && typeof txn.txHash === 'string') {
									txn.amount = txn.amount.toString();
									txn.timestamp = txn.timestamp.toString();
									return (
										<HistoryItem
											key={txn.txHash}
											handleShowTransaction={handleShowTransaction}
											transaction={txn}
										/>
									);
								}
								return null;
							})}
						</Box>
					);
				})}
			</Box>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [depositsArray]);

	let bridgeHistoryContent = History;

	if (depositsArray.length === 0 && !loading) {
		bridgeHistoryContent = (
			<Box className={classes.messageContainer}>
				<Box className={classes.title}>
					{t('bridge_history.no_transaction_found')}
				</Box>
				<Box className={classes.content} component="p">
					{t('bridge_history.no_transactions')}
				</Box>
			</Box>
		);
	}

	if (loading) {
		bridgeHistoryContent = (
			<Box className={classes.messageContainer}>
				<Box className={classes.title}>Loading Transactions</Box>
				<CircularProgress
					className={classes.circularProgress}
					progress={10}
					size={25}
				/>
			</Box>
		);
	}

	const historyConnectWallet = (
		<>
			<Box className={classes.walletIconContainer}>
				<AccountBalanceWalletIcon className={classes.walletIcon} />
			</Box>
			<Typography>
				{t('bridge_history.pre_connect_wallet')}{' '}
				<Button className={classes.connectToWallet} onClick={connectToMetamask}>
					{t('bridge_history.connect_wallet_link')}
				</Button>{' '}
				{t('bridge_history.post_connect_wallet')}
			</Typography>
		</>
	);

	const renderLoadingCircles = (
		<Box className={classes.loadingCircleBox}>
			<Skeleton variant="circle" width={10} height={10} />
			<Skeleton variant="circle" width={10} height={10} />
			<Skeleton variant="circle" width={10} height={10} />
		</Box>
	);

	const handleScroll = e => {
		const { offsetHeight, scrollTop, scrollHeight } = e.target;
		const scrollMargin = 50; // Margin for better response
		const scrollToBottom =
			offsetHeight + scrollTop >= scrollHeight - scrollMargin;
		if (scrollToBottom && loadMoreTx === false) {
			setLoadMoreTx(true);
			const newPage = page + 1;
			loadTx(newPage * txsPerPage, () => {
				setLoadMoreTx(false);
			});
			setPage(newPage);
		}
	};

	return (
		<Box className={classes.root} style={{ position: 'relative' }}>
			{title}
			{loadMoreTx && renderLoadingCircles}
			<Box
				onScroll={handleScroll}
				className={clsx(
					classes.container,
					connected && !wrongNetwork ? classes.scrollable : classes.alignCenter,
				)}
			>
				{connected && !wrongNetwork
					? bridgeHistoryContent
					: historyConnectWallet}
			</Box>
		</Box>
	);
});

BridgeHistory.defaultProps = {
	wrongNetwork: false,
};

BridgeHistory.propTypes = {
	handleShowTransaction: PropTypes.func.isRequired,
	wrongNetwork: PropTypes.bool,
};

export default BridgeHistory;

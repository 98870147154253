import React, { useContext } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import useStyles from 'styles/components/common/ConnectToMetamask';
import MetamaskFox from 'assets/images/metamask-fox.svg';
import modalsCtx from 'context/modals';
import { useIntl } from 'utils/helper';

const ConnectToMetamask = () => {
	const classes = useStyles();
	const t = useIntl();
	const { connectToMetamask } = useContext(modalsCtx);
	return (
		<Box className={classes.root}>
			<Box className={classes.container}>
				<Typography className={classes.heading}>
					{t('metamask_box.connect_wallet')}
				</Typography>
				<Typography className={classes.secondary}>
					{t('metamask_box.get_started')}
				</Typography>
				<MetamaskFox className={classes.metamaskIcon} />
				<Button className={classes.connectButton} onClick={connectToMetamask}>
					{t('metamask_box.connect_metamask')}
				</Button>
				<Typography className={classes.footerText}>
					{t('metamask_box.no_metamask')}{' '}
					<a
						className={classes.downloadLink}
						href="https://metamask.io/download.html"
						target="_blank"
						rel="noopener noreferrer"
					>
						{t('not_installed_metamask_modal.metamask_download')}
					</a>
				</Typography>
			</Box>
		</Box>
	);
};

export default ConnectToMetamask;

import { makeStyles, alpha } from '@energi/ui';

export default makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 9,
		backgroundColor: `${alpha(theme.palette.grey[1000], 0.8)}!important`,
	},
	wrapper: {
		position: 'relative',
		zIndex: theme.zIndex.drawer + 10,
	},
	root: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		width: '100%',
		height: '100%',
		margin: 'auto',
		background: `linear-gradient(0deg, ${alpha(
			theme.palette.common.white,
			0.09,
		)}, ${alpha(theme.palette.common.white, 0.09)}), #212121;`,
		borderRadius: 6,
		boxShadow: `0px 2px 4px ${alpha(
			theme.palette.common.black,
			0.14,
		)}, 0px 4px 5px ${alpha(
			theme.palette.common.black,
			0.12,
		)}, 0px 1px 10px ${alpha(theme.palette.common.black, 0.2)};`,
		overflow: 'hidden',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		zIndex: theme.zIndex.drawer + 2,
		[theme.breakpoints.up('sm')]: {
			position: 'absolute',
			top: -200,
			maxWidth: 440,
			height: 'auto',
		},
	},
	wrapperContainer: {
		padding: theme.spacing(3),
	},
	tokenListContainer: {
		overflowY: 'auto',
		'-webkitOverflowScrolling': 'touch',
		height: '100%',
		[theme.breakpoints.up('sm')]: {
			height: 'auto',
			maxHeight: 550,
		},
		[theme.breakpoints.down('sm')]: {
			height: 580,
		},
		'@media (max-height: 830px)': {
			height: 500,
		},
		'@media (max-height: 730px)': {
			height: 420,
		},
		'@media (max-height: 660px)': {
			height: 340,
		},
		'@media (max-height: 580px)': {
			height: 280,
		},
	},
	item: {
		borderRadius: 6,
		marginBottom: theme.spacing(1.5),
		padding: theme.spacing(1, 1.5),
	},
	itemIcon: {
		marginRight: theme.spacing(1.5),
	},
	itemName: {
		fontSize: 14,
		fontWeight: 400,
	},
	itemSymbol: {
		fontSize: 16,
		fontWeight: 600,
	},
	itemAmount: {
		fontSize: 16,
		fontWeight: 400,
		marginLeft: 'auto',
		color: theme.palette.secondary.main,
	},
	selectText: {
		fontSize: 12,
		fontWeight: 400,
		marginLeft: 'auto',
		marginRight: 5,
		color: theme.palette.secondary.main,
	},
	itemActive: {
		backgroundColor: alpha(theme.palette.common.white, 0.08),
	},
	showItem: {
		display: 'flex',
	},
	hiddenItem: {
		display: 'none',
	},
	title: {
		fontSize: 20,
		fontWeight: 600,
	},
	headerIcon: {
		top: -10,
		position: 'relative',
	},
	closeIcon: {
		marginLeft: 'auto',
	},
	backIcon: {
		marginRight: 30,
	},
	button: {
		display: 'block',
		border: 'none',
		borderRadius: 6,
		textAlign: 'left',
		backgroundColor: theme.palette.grey[800],
		padding: theme.spacing(1.75, 2),
		textTransform: 'none',
		height: 56,
		overflow: 'hidden',
	},
	buttonIcon: {
		marginRight: theme.spacing(1),
	},
	buttonName: {
		fontSize: 14,
		fontWeight: 400,
		color: theme.palette.grey[200],
		marginRight: theme.spacing(1),
		textTransform: 'none',
		whiteSpace: 'nowrap',
	},
	buttonSymbol: {
		fontSize: 16,
		fontWeight: 600,
		marginRight: theme.spacing(1),
	},
	buttonArrowDown: {
		color: theme.palette.secondary.main,
		marginLeft: 'auto',
	},
	textFieldSearch: {
		margin: theme.spacing(0, 0, 4, 0),
		'& .MuiInputBase-root': {
			fontSize: 16,
			color: alpha(theme.palette.common.white, 0.32),
			backgroundColor: alpha(theme.palette.common.white, 0.12),
			borderRadius: 6,
		},
		'& .MuiFilledInput-input': {
			color: theme.palette.common.white,
		},
	},
	searchIcon: {
		color: theme.palette.common.white,
	},
	circularProgress: {
		'&.MuiCircularProgress-root': {
			color: theme.palette.secondary.main,
		},
	},
	stableCoin: {
		fontSize: 16,
		fontWeight: 4,
		textAlign: 'center',
		borderRadius: 6,
		backgroundColor: alpha(theme.palette.common.white, 0.16),
		marginBottom: theme.spacing(3),
		padding: theme.spacing(1, 3),
	},
	errorOutlineIcon: {
		color: theme.palette.common.white,
	},
	addToMetamaskBox: {
		color: alpha(theme.palette.common.white, 0.7),
		width: '100%',
		padding: theme.spacing(2.2, 3),
		backgroundColor: alpha(theme.palette.common.white, 0.12),
		textAlign: 'center',
		textTransform: 'none',
		fontSize: 14,
	},
	checkbox: {
		marginRight: theme.spacing(1.5),
		transform: 'scale(1.2)',
		marginLeft: 0,
		paddingLeft: 0,
		'&:hover, &.Mui-checked:hover': {
			background: 'transparent',
		},
	},
	addSelectedToMetamask: {
		width: '100%',
		backgroundColor: theme.palette.primary.main,
		padding: theme.spacing(1.5, 0),
	},
}));

import { makeStyles, alpha } from '@energi/ui';

export default makeStyles(theme => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		position: 'relative',
		background: `linear-gradient(0deg, ${alpha(
			theme.palette.common.white,
			0.16,
		)}, ${alpha(theme.palette.common.white, 0.16)}), ${
			theme.palette.grey[1000]
		};`,
		borderRadius: 6,
		width: '100%',
		maxWidth: 650,
		height: '100%',
		maxHeight: 500,
		boxShadow: theme.shadows[5],
		margin: theme.spacing(0, 2),
		outline: 0,
	},
	closeButton: {
		position: 'absolute',
		top: theme.spacing(-6),
		right: theme.spacing(0),
		zIndex: 9,
	},
	backdrop: {
		backgroundColor: `${alpha(theme.palette.grey[1000], 0.8)}!important`,
	},
}));

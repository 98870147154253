import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Modal as MuiModal, IconButton, Backdrop, Fade } from '@energi/ui';
import { ReactTypeformEmbed } from 'react-typeform-embed';
import { Close as CloseIcon } from '@energi/ui/icons';
import useStyles from 'styles/components/common/TypeformModal';

const TypeformModal = props => {
	const { className, handleClose, show, url } = props;
	const classes = useStyles();
	let typeformEmbedContent;

	if (url) {
		typeformEmbedContent = <ReactTypeformEmbed url={url} autoOpen />;
	}
	return (
		<MuiModal
			className={clsx(classes.root, className)}
			open={show}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
				className: classes.backdrop,
			}}
		>
			<Fade in={show}>
				<div className={clsx(classes.paper, className)}>
					<IconButton className={classes.closeButton} onClick={handleClose}>
						<CloseIcon />
					</IconButton>
					{typeformEmbedContent}
				</div>
			</Fade>
		</MuiModal>
	);
};

TypeformModal.defaultProps = {
	className: '',
	show: false,
	handleClose: null,
};

TypeformModal.propTypes = {
	className: PropTypes.string,
	show: PropTypes.bool,
	handleClose: PropTypes.func,
	url: PropTypes.string.isRequired,
};

export default TypeformModal;

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Snackbar, Alert as MuiAlert, IconButton } from '@energi/ui';
import {
	Wifi as WifiIcon,
	WifiOff as WifiOffIcon,
	Close as CloseIcon,
} from '@energi/ui/icons';
import useStyles from 'styles/components/common/OfflineModal';

const TIMEOUT = 5000;
const Alert = props => {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const OfflineModal = ({ show, message }) => {
	const classes = useStyles();
	const timer = useRef();
	const [open, setOpen] = useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		if (!show) {
			timer.current = window.setTimeout(() => {
				setOpen(false);
			}, TIMEOUT);
		} else {
			setOpen(true);
		}
		// cleanup if we unmount
		return () => {
			clearTimeout(timer.current);
		};
	}, [show]);

	return (
		<Snackbar
			className={classes.root}
			open={open}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
		>
			<Alert
				action={
					<IconButton
						aria-label="close"
						color="inherit"
						size="small"
						onClick={handleClose}
					>
						<CloseIcon fontSize="inherit" className={classes.icon} />
					</IconButton>
				}
				className={classes.alert}
				icon={
					!show ? (
						<WifiIcon fontSize="inherit" className={classes.wifion} />
					) : (
						<WifiOffIcon fontSize="inherit" className={classes.wifioff} />
					)
				}
			>
				{message}
			</Alert>
		</Snackbar>
	);
};

OfflineModal.defaultProps = {
	show: false,
};

OfflineModal.propTypes = {
	show: PropTypes.bool,
	message: PropTypes.string.isRequired,
};

export default OfflineModal;

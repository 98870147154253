import React from 'react';
import BridgePage from 'components/layout/BridgePage';
import { isClient } from 'utils/helper';

const IndexPage = props => {
	if (isClient()) {
		return <BridgePage {...props} />;
	}
	return <div />;
};

export default IndexPage;

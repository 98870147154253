import { makeStyles, alpha } from '@energi/ui';

export default makeStyles(theme => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
		[theme.breakpoints.down('xs')]: {
			alignItems: 'flex-end',
		},
	},
	container: {
		position: 'relative',
		backgroundColor: theme.palette.grey[800],
		borderRadius: 6,
		maxWidth: 509,
		padding: theme.spacing(3),
		margin: theme.spacing(0, 2),
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(0, 0),
			borderBottomLeftRadius: 0,
			borderBottomRightRadius: 0,
		},
		boxShadow: `0px 24px 38px ${alpha(
			theme.palette.common.black,
			0.14,
		)}, 0px 3px 16px ${alpha(
			theme.palette.common.black,
			0.12,
		)}, 0px 5px 6px ${alpha(theme.palette.common.black, 0.2)};`,
		outline: 0,
		textAlign: 'center',
	},
	header: {
		paddingTop: theme.spacing(6),
	},
	metamaskLogo: {
		width: 183,
	},
	body: {
		fontSize: 16,
		color: alpha(theme.palette.common.white, 0.8),
		marginTop: theme.spacing(3),
	},
	footer: {
		fontSize: 16,
		color: theme.palette.common.white,
	},
	docsLink: {
		color: theme.palette.primary.main,
	},
	downloadLink: {
		fontWeight: 600,
		color: theme.palette.primary.main,
	},
	line: {
		height: 1,
		backgroundColor: theme.palette.grey[300],
		margin: theme.spacing(3, 0),
	},
	closeButton: {
		position: 'absolute',
		top: theme.spacing(3),
		right: theme.spacing(3),
		color: theme.palette.common.white,
		'&:hover': {
			backgroundColor: alpha(theme.palette.grey[100], 0.3),
		},
	},
	backdrop: {
		backgroundColor: `${alpha(theme.palette.grey[1000], 0.8)}!important`,
	},
}));

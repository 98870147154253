import { makeStyles } from '@energi/ui';

export default makeStyles(theme => ({
	root: {
		position: 'fixed',
		justifyContent: 'flex-start',
		bottom: theme.spacing(3),
		right: theme.spacing(4),
		width: '38%',
		[theme.breakpoints.down('xl')]: {
			width: '37%',
		},
		[theme.breakpoints.down('lg')]: {
			width: '33%',
		},
		'@media (max-width: 1720px)': {
			right: theme.spacing(3),
		},
		[theme.breakpoints.down('md')]: {
			right: theme.spacing(2),
			width: '37%',
		},
		[theme.breakpoints.down('sm')]: {
			right: theme.spacing(2),
			width: '72%',
		},
		[theme.breakpoints.down('xs')]: {
			position: 'static',
			width: '100%',
			right: 0,
			bottom: 0,
			margin: theme.spacing(2, 0),
		},
	},
	alert: {
		width: '100%',
		height: 72,
		fontSize: 16,
		fontWeight: 600,
		padding: theme.spacing(0, 3),
		borderRadius: 6,
		color: theme.palette.white,
		backgroundColor: theme.palette.red[300],
		alignItems: 'center',
		justifyContent: 'space-around',
	},
	button: {
		padding: theme.spacing(1.2),
	},
	icon: {
		transform: 'scale(1.4)',
	},
	blankIcon: {
		display: 'none',
	},
}));

import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box, Button, IconButton, useTheme, useMediaQuery } from '@energi/ui';
import {
	ArrowDropDown as ArrowDropDownIcon,
	ArrowDropUp as ArrowDropUpIcon,
	Close as CloseIcon,
} from '@energi/ui/icons';
import { useMetamask } from '@energi/energi-wallet';
import BridgeHistory from 'components/bridge/BridgeHistory';
import useStyles from 'styles/components/bridge/ChooseWallet';
import modalsCtx from 'context/modals';
import ConnectToMetamask from 'components/common/ConnectToMetamask';
import { VALID_CHAINS } from 'utils/constants/chains';
import { shorten, useIntl } from 'utils/helper';
import WalletInfo from 'components/common/WalletInfo';

const ChooseWallet = ({ handleShowTransaction }) => {
	const t = useIntl();
	const classes = useStyles();
	const theme = useTheme();
	const smallScreenSize = useMediaQuery(theme.breakpoints.down('sm'));
	const xSmallScreenSize = useMediaQuery(theme.breakpoints.down('xs'));
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const { address, chainId, connected } = useMetamask();
	const { connectToMetamask } = useContext(modalsCtx);
	const wrongNetwork = !VALID_CHAINS.includes(chainId);

	const toggleModal = event => {
		if (open) {
			setAnchorEl(null);
		} else {
			setAnchorEl(event.currentTarget);
		}
	};

	useEffect(() => {
		if (!smallScreenSize) {
			setAnchorEl(null);
		}
	}, [smallScreenSize]);

	useEffect(() => {
		if (!address && smallScreenSize) {
			setAnchorEl(null);
		}
	}, [address, smallScreenSize]);

	const buttonContent =
		connected && !wrongNetwork ? (
			<Button
				className={classes.button}
				variant="outlined"
				onClick={toggleModal}
			>
				<Box
					className={classes.buttonContent}
					display="flex"
					component="span"
					alignItems="center"
					textOverflow="ellipsis"
					overflow="hidden"
				>
					{xSmallScreenSize ? shorten(address, 3) : shorten(address, 7)}
					{!open ? (
						<ArrowDropDownIcon className={classes.buttonArrow} />
					) : (
						<ArrowDropUpIcon className={classes.buttonArrow} />
					)}
				</Box>
			</Button>
		) : (
			<Button
				className={classes.connectButton}
				variant="outlined"
				onClick={connectToMetamask}
			>
				{t('metamask_box.connect_metamask')}
			</Button>
		);

	const walletMetaMaskContent =
		connected && !wrongNetwork ? (
			<Box className={classes.mobileRightSideContent}>
				<WalletInfo />
				<BridgeHistory
					handleShowTransaction={handleShowTransaction}
					wrongNetwork={wrongNetwork}
				/>
			</Box>
		) : (
			<ConnectToMetamask />
		);

	const modalContent = (
		<Box
			className={clsx(
				classes.historyModal,
				!open ? classes.hiddenDisplayModal : null,
			)}
			onClose={toggleModal}
		>
			<Box className={classes.modalContent}>
				{xSmallScreenSize ? (
					<IconButton className={classes.closeButton} onClick={toggleModal}>
						<CloseIcon />
						<span className={classes.walletText}>Wallet</span>
					</IconButton>
				) : null}
				{walletMetaMaskContent}
			</Box>
		</Box>
	);

	return (
		<>
			{buttonContent}
			{smallScreenSize ? modalContent : null}
		</>
	);
};

ChooseWallet.defaultProps = {
	handleShowTransaction: null,
};

ChooseWallet.propTypes = {
	handleShowTransaction: PropTypes.func,
};

export default ChooseWallet;

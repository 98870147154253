import React, { useState } from 'react';
import { useMetamask } from '@energi/energi-wallet';
import { Button, Link, Box, Skeleton } from '@energi/ui';
import { copyToClipboard } from '@energi/utils';
import {
	FileCopyOutlined as FileCopyOutlinedIcon,
	LaunchOutlined as LaunchOutlinedIcon,
} from '@material-ui/icons';
import useStyles from 'styles/components/common/WalletInfo';
import MetamaskLogo from 'assets/images/metamask-fox.svg';
import {
	ALL_CHAINS,
	VALID_CHAINS,
	SUPPORTED_NETWORK,
} from 'utils/constants/chains';
import { useIntl } from 'utils/helper';

const WalletInfo = () => {
	const classes = useStyles();
	const t = useIntl();
	const { address, chainId, disconnect } = useMetamask();
	const isValidChain = VALID_CHAINS.includes(chainId);
	const network = isValidChain ? ALL_CHAINS[chainId].heading : '';
	const [copied, setCopied] = useState(false);
	const explorerUrl = isValidChain
		? `${ALL_CHAINS[chainId].explorerUrl}address/${address}`
		: '';

	const copyAddress = async () => {
		if (address) {
			await copyToClipboard(address);
			setCopied(true);
			setTimeout(() => {
				setCopied(false);
			}, 500);
		}
	};

	return (
		<div className={classes.root}>
			<div className={classes.innerBox}>
				<div className={classes.flexBox}>
					<MetamaskLogo className={classes.metamaskIcon} />
					<span className={classes.metamaskTitle}>
						{t('choose_wallet.metamask')}
					</span>
				</div>
				<p className={classes.networkText}>
					{network} {t('choose_wallet.network')}
				</p>

				<p className={classes.addressBox}>
					{address ? (
						address.toLowerCase()
					) : (
						<Skeleton
							rect="true"
							width={300}
							height={25}
							className={classes.skeleton}
						/>
					)}
				</p>

				<div className={classes.iconBox}>
					<Box className={classes.iconBoxInner} onClick={copyAddress}>
						<div className={classes.iconBoxIcon}>
							<FileCopyOutlinedIcon />
						</div>
						{copied
							? t('metamask_box.copied_address')
							: t('metamask_box.copy_address')}
					</Box>
					<Link href={explorerUrl} target="_blank" rel="noreferrer noopener">
						<div className={classes.iconBoxInner}>
							<div className={classes.iconBoxIcon}>
								<LaunchOutlinedIcon />
							</div>
							{isValidChain
								? t(`metamask_box.view_${SUPPORTED_NETWORK[chainId]}_explorer`)
								: 'View on Explorer'}
						</div>
					</Link>
				</div>
				<div>
					<Button
						variant="text"
						className={classes.disconnectButton}
						onClick={() => disconnect()}
						disableElevation
					>
						{t('metamask_box.disconnect')}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default WalletInfo;

import { makeStyles } from '@energi/ui';

export default makeStyles(theme => ({
	mobileRightSideContent: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		height: '93%',
		marginLeft: theme.spacing(3),
		backgroundColor: theme.palette.grey[900],
		[theme.breakpoints.down('xs')]: {
			marginLeft: theme.spacing(0),
			paddingTop: 44,
		},
		[theme.breakpoints.up('sm')]: {
			padding: theme.spacing(2),
		},
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
		[theme.breakpoints.up('lg')]: {
			padding: theme.spacing(3),
		},
	},
	button: {
		display: 'block',
		textAlign: 'left',
		borderRadius: 6,
		textTransform: 'none',
		color: theme.palette.common.white,
		borderColor: theme.palette.common.white,
		marginLeft: theme.spacing(1),
		padding: theme.spacing(0.625, 1.5),
	},
	connectButton: {
		borderRadius: 4,
		color: theme.palette.primary.main,
		borderColor: theme.palette.primary.main,
		padding: theme.spacing(0.625, 2),
		fontSize: 14,
		fontWeight: 600,
	},
	buttonArrow: {
		position: 'relative',
		right: -6,
	},
	buttonContent: {
		'&::before': {
			content: '""',
			width: 12,
			height: 12,
			backgroundColor: theme.palette.primary.main,
			borderRadius: '100%',
			marginRight: theme.spacing(2),
		},
	},
	modalContent: {
		position: 'absolute',
		left: 0,
		bottom: 0,
		right: 0,
		width: '100%',
		margin: theme.spacing(0, 'auto'),
		outline: 0,
		[theme.breakpoints.down('sm')]: {
			top: 2,
		},
	},
	closeButton: {
		position: 'absolute',
		top: theme.spacing(2),
		left: theme.spacing(2),
		[theme.breakpoints.down('xs')]: {
			top: 7,
			left: 1,
			display: 'flex',
			justifyContent: 'space-between',
			width: 105,
		},
	},
	walletText: {
		fontSize: 16,
		fontWeight: 600,
	},
	historyModal: {
		display: 'block',
		backgroundColor: '#242526',
		position: 'fixed',
		right: 0,
		[theme.breakpoints.down('sm')]: {
			width: '70%',
			height: '85%',
			top: '8%',
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			height: '100%',
			top: 0,
		},
	},
	hiddenDisplayModal: {
		display: 'none',
	},
}));

import React, { useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
	Modal as MuiModal,
	Backdrop,
	Fade,
	IconButton,
	Chip,
	ButtonBase,
	Tooltip,
	withStyles,
} from '@energi/ui';
import { isMobile, copyToClipboard, getWeb3 } from '@energi/utils';
import {
	Close as CloseIcon,
	ArrowBack as ArrowIcon,
	FileCopyOutlined as FileCopyOutlinedIcon,
	InfoOutlined as InfoIcon,
} from '@energi/ui/icons';
import { getExplorerLink } from 'utils/get-contract';
import useStyles from 'styles/components/common/TransactionInfoModal';
import latestBlockCtx from 'context/latestBlock';
import { toFixedDecimals, shorten, useIntl } from 'utils/helper';
import { ALL_CHAINS } from 'utils/constants/chains';

const CustomTooltip = withStyles(() => ({
	tooltip: {
		backgroundColor: '#7F7F7F',
		textAlign: 'center',
		fontSize: 12,
		fontStyle: 'normal',
		lineHeight: 1.8,
		letterSpacing: 0.4,
		fontWeight: 400,
	},
}))(Tooltip);

const TransactionInfoModal = props => {
	const classes = useStyles();
	const t = useIntl();

	const { className, handleClose, show, data } = props;
	const [sourceConfirmationCount, setSourceConfirmationCount] = useState(0);
	const [destinationConfirmationCount, setDestinationConfirmationCount] =
		useState(0);
	const [copied, setCopied] = useState(null);
	const [copyTimeout, setCopyTimeout] = useState(null);

	const { latestBlock } = useContext(latestBlockCtx);

	const sourceNetwork = ALL_CHAINS[data.source];
	const destinationNetwork = ALL_CHAINS[data.destination];
	const sourceLatestBlock = latestBlock[sourceNetwork.chainId];
	const destinationLatestBlock = latestBlock[destinationNetwork.chainId];

	const sourceTxnLink = getExplorerLink(
		sourceNetwork.chainId,
		data?.txHash,
		'transaction',
	);
	const destinationTxnLink = data?.destinationHash
		? getExplorerLink(
				destinationNetwork.chainId,
				data?.destinationHash,
				'transaction',
		  )
		: '';

	const calculateConfirmation = (txHash, chainId, setConfirmationCount) => {
		if (txHash) {
			try {
				getWeb3(chainId)
					.eth.getTransaction(txHash)
					.then(tx => {
						if (tx?.blockNumber && latestBlock[chainId]) {
							setConfirmationCount(
								Math.abs(
									Number(latestBlock[chainId]) - Number(tx?.blockNumber),
								),
							);
						} else {
							setConfirmationCount(0);
						}
					});
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error('Error on calculating the confirmation: ', err);
			}
		} else {
			setConfirmationCount(0);
		}
	};

	useMemo(() => {
		calculateConfirmation(
			data?.txHash,
			sourceNetwork.chainId,
			setSourceConfirmationCount,
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceLatestBlock, show, data?.txHash]);

	useMemo(() => {
		calculateConfirmation(
			data?.destinationHash,
			destinationNetwork.chainId,
			setDestinationConfirmationCount,
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [destinationLatestBlock, data?.destinationHash, show]);

	const isDelayedOrPending =
		data?.status === 'DELAYED' ? classes.statusDelayed : classes.statusPending;
	const statusChipTheme =
		data?.status === 'CONFIRMED' ? classes.statusConfirmed : isDelayedOrPending;

	const copyAddress = async (address, type) => {
		copyToClipboard(address);
		setCopied(type);
		if (copyTimeout) {
			clearTimeout(copyTimeout);
			setCopyTimeout(null);
		}
		const timeout = setTimeout(() => {
			setCopied(null);
			setCopyTimeout(null);
		}, 1000);
		setCopyTimeout(timeout);
	};

	return (
		<MuiModal
			className={clsx(classes.root, className)}
			open={show}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
				className: classes.backdrop,
			}}
		>
			<Fade in={show}>
				<div className={clsx(classes.paper, className)}>
					<div className={classes.header}>
						<div className={classes.title}>
							{isMobile && (
								<IconButton
									aria-label="close"
									className={classes.closeArrowButton}
									onClick={handleClose}
								>
									<ArrowIcon />
								</IconButton>
							)}
							{t('bridge_history.transfer_info')}
						</div>
						{!isMobile && (
							<IconButton
								aria-label="close"
								className={classes.closeButton}
								onClick={handleClose}
							>
								<CloseIcon />
							</IconButton>
						)}
					</div>
					<div className={classes.content}>
						<div className={classes.summaryHeader}>
							<p>
								<span>{t('bridge_history.from')}:</span> {sourceNetwork.heading}
							</p>
							<p>
								<span>{t('bridge_history.to')}:</span>{' '}
								{destinationNetwork.heading}
							</p>
						</div>
						<div className={classes.line} />
						<div className={classes.summary}>
							<p>{t('bridge_history.transfer_id')}</p>
							<div className={classes.tooltipBox}>
								<CustomTooltip
									className={classes.test}
									title={data?.id || ''}
									placement="top"
									arrow
								>
									<p aria-hidden="true" style={{ cursor: 'pointer' }}>
										{shorten(data?.id)}
									</p>
								</CustomTooltip>
								<CustomTooltip
									enterTouchDelay={0}
									leaveTouchDelay={400}
									title={
										copied === 'id'
											? t('bridge_history.copied')
											: t('bridge_history.click_to_copy')
									}
									placement="top"
									arrow
								>
									<FileCopyOutlinedIcon
										onClick={() => copyAddress(data?.id, 'id')}
									/>
								</CustomTooltip>
							</div>
						</div>
						<div className={classes.line} />
						<div className={classes.summary}>
							<p>{t('bridge_history.sent')}</p>
							<p>
								{toFixedDecimals(data?.amount, 6)} {data?.sourceToken.symbol}
							</p>
						</div>
						<div className={classes.line} />
						<div className={classes.summary}>
							<p>{t('bridge_history.origin_confirmation')}</p>
							<p>
								{sourceConfirmationCount} / {sourceNetwork.confirmationCount}
							</p>
						</div>
						<div className={classes.line} />
						<div className={classes.summary}>
							<p>{t('bridge_history.destination_confirmation')}</p>
							<p>
								{destinationConfirmationCount} /{' '}
								{destinationNetwork.confirmationCount}
							</p>
						</div>
						<div className={classes.line} />
						<div className={classes.summary}>
							<p>{t('bridge_history.fee')}</p>
							<p>
								{toFixedDecimals(data?.fee, 6)} {data.sourceNativeCoin.symbol}
							</p>
						</div>
						<div className={classes.line} />
						<div className={classes.summary}>
							<p>{t('bridge_history.received')}</p>
							<p>
								{data?.destinationHash
									? `${toFixedDecimals(data?.amount, 6)} ${
											data?.destinationToken.symbol
									  }`
									: '...'}
							</p>
						</div>
						<div className={classes.line} />
						<div className={classes.summary}>
							<p>{t('bridge_history.address')}</p>
							<div className={classes.tooltipBox}>
								<CustomTooltip
									title={data?.address || ''}
									placement="top"
									arrow
									style={{ fontSize: '19px' }}
								>
									<p aria-hidden="true" style={{ cursor: 'pointer' }}>
										{shorten(data?.address)}
									</p>
								</CustomTooltip>
								<CustomTooltip
									title={
										copied === 'address'
											? t('bridge_history.copied')
											: t('bridge_history.click_to_copy')
									}
									placement="top"
									arrow
								>
									<FileCopyOutlinedIcon
										onClick={() => copyAddress(data?.address, 'address')}
									/>
								</CustomTooltip>
							</div>
						</div>
						<div className={classes.line} />
						<div className={classes.link}>
							<p>{t('bridge_history.explorers')}</p>
							<div className={classes.chips}>
								<ButtonBase>
									<Chip
										label={sourceNetwork.explorer}
										component="a"
										disabled={Boolean(!sourceTxnLink)}
										target="_blank"
										rel="noopener noreferrer"
										href={sourceTxnLink}
										clickable
									/>
								</ButtonBase>
								<ButtonBase className={classes.destinationButton}>
									<Chip
										label={destinationNetwork.explorer}
										component="a"
										disabled={Boolean(!destinationTxnLink)}
										target="_blank"
										rel="noopener noreferrer"
										href={destinationTxnLink}
										clickable
									/>
								</ButtonBase>
							</div>
						</div>
						<div className={classes.line} />
						<div className={clsx(classes.summary, classes.summaryFooter)}>
							<p>{data?.date}</p>
							<div className={classes.aligned}>
								<Chip className={statusChipTheme} label={data?.statusText} />
								{data?.status !== 'CONFIRMED' && <InfoIcon />}
							</div>
						</div>
					</div>
				</div>
			</Fade>
		</MuiModal>
	);
};

TransactionInfoModal.defaultProps = {
	className: '',
	show: false,
	handleClose: null,
	data: null,
};

TransactionInfoModal.propTypes = {
	className: PropTypes.string,
	show: PropTypes.bool,
	handleClose: PropTypes.func,
	data: PropTypes.object,
};

export default TransactionInfoModal;

import { makeStyles, alpha } from '@energi/ui';

const drawerWidth = 222;

export default makeStyles(theme => ({
	backdrop: {
		display: 'none',
		zIndex: theme.zIndex.drawer + 1,
		backgroundColor: `${alpha(theme.palette.grey[1000], 0.8)}!important`,
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	energiLogo: {
		display: 'none',
		flexDirection: 'column',
		height: 50,
		margin: theme.spacing(2, 0),
		[theme.breakpoints.up('lg')]: {
			display: 'flex',
		},
	},
	communitySocialIconsWrap: {
		display: 'none',
		marginTop: 'auto',
		padding: theme.spacing(0, 3),
		[theme.breakpoints.up('lg')]: {
			display: 'block',
		},
	},
	community: {
		fontSize: 16,
		fontWeight: 600,
	},
	socialIcon: {
		fontSize: 24,
		width: 24,
		height: 24,
		fill: theme.palette.common.white,
		color: theme.palette.common.white,
		'&:hover': {
			fill: theme.palette.primary.main,
			color: theme.palette.primary.main,
		},
	},
	version: {
		fontSize: 14,
	},
	versionShort: {
		display: 'block',
		marginTop: 'auto',
		padding: theme.spacing(0, 2),
		textAlign: 'center',
		[theme.breakpoints.up('lg')]: {
			display: 'none',
		},
	},
	menuButton: {
		display: 'block',
		left: theme.spacing(-1.4),
		margin: theme.spacing(1, 'auto'),
		[theme.breakpoints.up('lg')]: {
			display: 'none',
		},
	},
	hide: {
		display: 'none',
	},
	paperRoot: {
		paddingTop: theme.spacing(8),
		paddingBottom: theme.spacing(11),
		backgroundColor: theme.palette.grey[1002],
		border: 'none',
		[theme.breakpoints.up('sm')]: {
			paddingTop: theme.spacing(11),
			paddingBottom: theme.spacing(11),
		},
		[theme.breakpoints.up('md')]: {
			paddingTop: theme.spacing(0),
			paddingBottom: theme.spacing(0),
			backgroundColor: theme.palette.grey[900],
		},
	},
	drawer: {
		width: '100%',
		flexShrink: 0,
		whiteSpace: 'nowrap',
		zIndex: theme.zIndex.drawer + 9,
		[theme.breakpoints.up('md')]: {
			display: 'block',
			width: theme.spacing(9),
		},
		[theme.breakpoints.up('lg')]: {
			display: 'block',
			width: drawerWidth,
		},
	},
	drawerOpen: {
		width: '100%',
		marginRight: '-100%',
		overflowX: 'hidden',
		[theme.breakpoints.up('md')]: {
			width: drawerWidth,
			marginRight: -drawerWidth,
		},
	},
	drawerClose: {
		overflowX: 'hidden',
		width: 0,
		[theme.breakpoints.up('md')]: {
			width: theme.spacing(9),
		},
		[theme.breakpoints.up('lg')]: {
			width: drawerWidth,
		},
	},
	toolbar: {
		display: 'none',
		alignItems: 'end',
		justifyContent: 'space-between',
		padding: theme.spacing(0, 3),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	toolbarBookmark: {
		display: 'none',
		alignItems: 'end',
		justifyContent: 'space-between',
		padding: theme.spacing(0.8, 3),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			top: 20,
			position: 'relative',
		},
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	rootList: {
		flexDirection: 'column',
		color: theme.palette.common.white,
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		[theme.breakpoints.up('lg')]: {
			'&': {
				flexDirection: 'row',
			},
		},
	},
	listItemIcon: {
		minWidth: 'auto',
		[theme.breakpoints.up('lg')]: {
			minWidth: 33,
		},
		'& > svg': {
			fill: alpha(theme.palette.common.white, 0.7),
			height: 24,
		},
	},
	listItemText: {
		color: alpha(theme.palette.common.white, 0.7),
		'& .MuiTypography-root': {
			fontSize: 12,
		},
		[theme.breakpoints.up('lg')]: {
			'&': {
				'& .MuiTypography-root': {
					fontSize: 16,
				},
			},
		},
	},
	listItemTextActive: {
		color: theme.palette.common.white,
	},
	listItemIconActive: {
		'& > svg': {
			fill: theme.palette.common.white,
			height: 24,
		},
	},
	energiLogoOpen: {
		display: 'block',
	},
	menuButtonOpen: {
		margin: theme.spacing(1, 0),
	},
	rootListOpen: {
		flexDirection: 'row',
	},
	listItemIconOpen: {
		minWidth: 33,
	},
	listItemTextOpen: {
		'&': {
			'& .MuiTypography-root': {
				fontSize: 16,
			},
		},
	},
	divider: {
		margin: theme.spacing(2, 0),
	},
	communitySocialIconsWrapOpen: {
		display: 'block',
	},
	versionShortClose: {
		display: 'none',
	},
}));

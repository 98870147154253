import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
	Box,
	Typography,
	ButtonBase,
	Tooltip,
	withStyles,
	useMediaQuery,
} from '@energi/ui';
import { ArrowRightAlt, InfoOutlined as InfoIcon } from '@energi/ui/icons';
import sdk from '@energi/energi-sdk';
import { useMetamask } from '@energi/energi-wallet';
import tokenListCtx from 'context/tokenlist';
import useStyles from 'styles/components/bridge/HistoryItem';
import DefaultLogo from 'assets/tokens/DefaultTokenIcon.svg'; // use for Tokens that have no icon
import { toFixedDecimals, useIntl } from 'utils/helper';
import { ALL_CHAINS, VALID_CHAINS } from 'utils/constants/chains';

const CustomTooltip = withStyles(theme => ({
	tooltip: {
		backgroundColor: 'rgba(127, 127, 127)',
		color: '#FFF',
		padding: theme.spacing(1, 1),
		fontSize: 12,
		lineHeight: 1.8,
		fontWeight: 500,
	},
}))(Tooltip);

const HistoryItem = React.memo(({ transaction, handleShowTransaction }) => {
	const classes = useStyles();
	const t = useIntl();
	const { address } = useMetamask();
	const sourceNetwork = ALL_CHAINS[transaction?.source];
	const destinationNetwork = ALL_CHAINS[transaction?.destination];
	const settled = transaction?.settled;
	const smallScreen = useMediaQuery(`(max-width: 1440px)`);

	const TRANSACTION_STATUS = {
		CONFIRMED: t('bridge_history.completed'),
		PENDING: t('bridge_history.pending'),
		DELAYED: t('bridge_history.delayed'),
	};

	const { tokenList: allTokens, getNativeCoin } = useContext(tokenListCtx);

	const combinedTokenList = VALID_CHAINS.map(
		networkId => allTokens[networkId] || [],
	)
		.flat()
		.map((item, index) => {
			const newItem = item;
			newItem.id = index + 1;
			return newItem;
		});

	const wrapped = Object.values(ALL_CHAINS).map(item => item.wrapped);
	const tokenList = combinedTokenList.map(item => {
		if (wrapped.includes(item.symbol)) {
			const chain = Object.values(ALL_CHAINS).find(
				chain => chain.wrapped === item.symbol,
			);
			const nativeCoin = getNativeCoin(chain.chainId);
			const newToken = { ...item };
			newToken.symbol = nativeCoin.symbol; // native coin symbol
			newToken.name = nativeCoin.name; // native coin name
			return newToken;
		}

		return item;
	});

	const datetime = new Date(transaction.timestamp * 1000); // times 1000 to convert to milliseconds
	const transactionTime = `${datetime.toISOString().substring(0, 10)} ${datetime
		.toTimeString()
		.substring(0, 8)}`;
	const transactionDate = datetime.toString().substr(4, 11);
	let statusText = '...';

	const getTokenDetails = (tokenAddress, network) => {
		let token;
		if (tokenAddress === sdk.ZERO_ADDRESS) {
			const networkChainId = ALL_CHAINS[network].chainId;
			[token] = allTokens[networkChainId];
		} else {
			token = tokenList.find(
				item => item.address.toLowerCase() === tokenAddress?.toLowerCase(),
			);
		}

		return {
			decimals: token?.decimals,
			icon: token?.icon ?? DefaultLogo,
			name: token?.name,
			symbol: token?.symbol,
		};
	};

	const isDelayedOrPending = transaction.delayed ? 'DELAYED' : 'PENDING';
	const currentStatus = transaction.settled ? 'CONFIRMED' : isDelayedOrPending;
	statusText = TRANSACTION_STATUS[currentStatus];

	const sourceToken = getTokenDetails(transaction?.token, transaction?.source);
	const sourceNativeCoin = getNativeCoin(sourceNetwork.chainId);

	const destinationToken = getTokenDetails(
		transaction?.coToken,
		transaction?.destination,
	);

	// Setting the amount decimals to 4 decimals on smaller screen,
	// so the it doesn't break the components in transaction history items
	// passing undefined means that the amount is the same data as it's coming from subgraph
	const formattedAmount = toFixedDecimals(
		transaction?.amount,
		smallScreen && sourceToken.decimals >= 4 ? 4 : undefined,
	);

	return (
		<ButtonBase
			className={classes.root}
			onClick={() =>
				handleShowTransaction({
					...transaction,
					sourceToken,
					destinationToken,
					date: transactionDate,
					status: currentStatus,
					statusText,
					address,
					destinationHash: settled?.txHash,
					amount: formattedAmount,
					sourceNativeCoin,
				})
			}
		>
			<Box className={classes.row}>
				<Box className={classes.txnStatus}>
					<CustomTooltip
						disableTouchListener
						title={t('bridge_history.transaction_direction').replace(
							/[$]./g,
							n => {
								return [sourceNetwork.heading, destinationNetwork.heading][
									n[1]
								];
							},
						)}
						placement="top"
					>
						<Box className={classes.icons}>
							<img src={sourceNetwork.icon} alt={sourceToken?.symbol} />
							<ArrowRightAlt className={classes.arrowIcon} />
							<img
								src={destinationNetwork.icon}
								alt={destinationToken?.symbol}
							/>
						</Box>
					</CustomTooltip>
					<Box className={classes.source}>
						<Box className={classes.tokenTitle}>
							<Typography className={classes.bold} component="span">
								{sourceToken?.symbol}{' '}
							</Typography>
							<Typography className={classes.name} component="span">
								{sourceToken?.name}
							</Typography>
						</Box>
						<Typography className={classes.transactionDate} component="span">
							{transactionTime}
						</Typography>
					</Box>
					<Box className={classes.amount}>
						<Typography className={classes.transactionAmount} component="span">
							{formattedAmount} {sourceToken?.symbol}
						</Typography>
						<Box className={classes.statusSection}>
							<Typography
								className={clsx(
									classes.confirmationStatus,
									currentStatus === 'CONFIRMED'
										? classes.confirmedStatus
										: classes.pendingStatus,
									currentStatus === 'DELAYED' && classes.delayedStatus,
								)}
								component="p"
							>
								{statusText}
							</Typography>
							{currentStatus !== 'CONFIRMED' &&
								(transaction?.delayed ? (
									<CustomTooltip
										disableTouchListener
										title={t('bridge_history.delayed_tooltip')}
										placement="top"
									>
										<InfoIcon />
									</CustomTooltip>
								) : (
									<InfoIcon />
								))}
						</Box>
					</Box>
				</Box>
			</Box>
		</ButtonBase>
	);
});

HistoryItem.defaultProps = {
	transaction: null,
};

HistoryItem.propTypes = {
	transaction: PropTypes.shape({
		id: PropTypes.string,
		amount: PropTypes.string.isRequired,
		token: PropTypes.string.isRequired,
		source: PropTypes.string.isRequired,
		destination: PropTypes.string,
		timestamp: PropTypes.string,
		blockNumber: PropTypes.string,
		txHash: PropTypes.string,
		coToken: PropTypes.string,
		decimals: PropTypes.string,
		delayed: PropTypes.bool,
		settled: PropTypes.shape({
			id: PropTypes.string,
			txHash: PropTypes.string,
		}),
	}),
	handleShowTransaction: PropTypes.func.isRequired,
};

export default HistoryItem;

import { makeStyles, alpha } from '@energi/ui';

// Tab menu appears in front of the left sidebar on mobile mode
const ZINDEX_FRONT = 10;

export default makeStyles(theme => ({
	paperTabs: {
		position: 'fixed',
		width: '100%',
		left: 0,
		bottom: 0,
		backgroundColor: theme.palette.grey[900],
		zIndex: theme.zIndex.drawer + ZINDEX_FRONT,
		[theme.breakpoints.up('md')]: {
			zIndex: theme.zIndex.drawer,
		},
	},
	tab: {
		fontSize: 12,
		textTransform: 'none',
		textDecoration: 'none',
		minWidth: theme.spacing(8),
		color: alpha(theme.palette.common.white, 0.7),
		'&:hover': {
			textTransform: 'none',
			textDecoration: 'none',
		},
		'& svg': {
			fill: alpha(theme.palette.common.white, 0.7),
			height: 24,
		},
		'&.Mui-selected': {
			color: `${theme.palette.common.white}!important`,
			'& svg': {
				fill: theme.palette.common.white,
			},
		},
		[theme.breakpoints.up('sm')]: {
			fontSize: 14,
			minWidth: theme.spacing(9),
		},
	},
	tabActive: {
		color: `${theme.palette.common.white}!important`,
		'& svg': {
			fill: theme.palette.common.white,
		},
	},
}));

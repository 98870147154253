import { makeStyles } from '@energi/ui';

export default makeStyles(theme => ({
	root: {
		position: 'absolute',
		width: '100%',
		height: 54,
		display: 'flex',
		flexGrow: 1,
		flexWrap: 'wrap',
		opacity: 1,
		flex: 1,
		top: 0,
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: theme.palette.orange[400],
		padding: theme.spacing(0, 2),
		color: '#212121',
		zIndex: 1210,
		[theme.breakpoints.down('sm')]: {
			height: 62,
			top: 78,
		},
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
			height: 110,
			top: 54,
		},
	},
	item: {
		display: 'flex',
		alignItems: 'center',
		fontWeight: 400,
		fontSize: 14,
		flex: 1,
	},
	icon: {
		width: '1.325rem',
		marginRight: theme.spacing(2),
	},
	menu: {
		display: 'flex',
		flex: 1,
		justifyContent: 'flex-end',
		'& > ul': {
			margin: 0,
			padding: 0,
			display: 'flex',
			listStyleType: 'none',
			'& > li > a, & > li > button': {
				margin: theme.spacing(0, 1.5),
				fontWeight: 600,
				cursor: 'pointer',
				textDecoration: 'none',
				fontSize: 14,
				color: '#212121',
			},
		},
	},
}));

import { makeStyles, alpha } from '@energi/ui';

export default makeStyles(theme => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		position: 'relative',
		background: `linear-gradient(0deg, ${alpha(
			theme.palette.common.white,
			0.09,
		)}, ${alpha(theme.palette.common.white, 0.09)}), ${
			theme.palette.grey[1000]
		};`,
		borderRadius: 6,
		maxWidth: 720,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(8, 2, 2, 2),
		margin: theme.spacing(0, 2),
		outline: 0,
		overflowY: 'auto',
		'-webkitOverflowScrolling': 'touch',
		maxHeight: 480,
		[theme.breakpoints.up('sm')]: {
			overflowY: 'hidden',
			maxHeight: '100%',
			padding: theme.spacing(10, 3, 3, 3),
		},
	},
	closeButton: {
		position: 'absolute',
		top: theme.spacing(1),
		right: theme.spacing(1),
	},
	backdrop: {
		backgroundColor: `${alpha(theme.palette.grey[1000], 0.8)}!important`,
	},
}));

import { alpha, makeStyles } from '@energi/ui';

export default makeStyles(theme => ({
	root: {
		borderRadius: 6,
		position: 'relative',
		left: 0,
		bottom: 0,
		right: 0,
		width: '100%',
		maxWidth: 600,
		marginBottom: theme.spacing(2),
		zIndex: theme.zIndex.drawer,
		[theme.breakpoints.up('sm')]: {
			maxWidth: 472,
			position: 'fixed',
			left: 'auto',
			right: 24,
			bottom: theme.spacing(12),
			marginBottom: 0,
		},
		[theme.breakpoints.up('md')]: {
			maxWidth: 363,
			bottom: theme.spacing(3),
		},
		[theme.breakpoints.up('lg')]: {
			maxWidth: 473,
		},
		[theme.breakpoints.up('xl')]: {
			maxWidth: 673,
		},
	},
	container: {
		width: '100%',
		borderRadius: 6,
		overflow: 'hidden',
		boxShadow: `0px 24px 38px ${alpha(
			theme.palette.common.black,
			0.14,
		)}, 0px 3px 16px ${alpha(
			theme.palette.common.black,
			0.12,
		)}, 0px 5px 6px ${alpha(theme.palette.common.black, 0.2)};`,
		background: `linear-gradient(0deg, ${alpha(
			theme.palette.common.white,
			0.12,
		)}, ${alpha(theme.palette.common.white, 0.12)}), ${
			theme.palette.grey[1000]
		}`,
		borderBottomLeftRadius: 6,
		borderBottomRightRadius: 6,
		[theme.breakpoints.up('sm')]: {
			maxHeight: 512,
		},
	},
	header: {
		display: 'flex',
		padding: theme.spacing(1, 2),
		color: theme.palette.grey[1000],
		borderTopLeftRadius: 6,
		borderTopRightRadius: 6,
		backgroundColor: theme.palette.orange[400],
		[theme.breakpoints.up('sm')]: {
			padding: theme.spacing(1.5, 2),
		},
	},
	headerGreen: {
		backgroundColor: theme.palette.green[900],
		color: theme.palette.white,
	},
	headerError: {
		backgroundColor: theme.palette.error.main,
	},
	headerDelayed: {
		backgroundColor: theme.palette.yellow[400],
		'& button': {
			backgroundColor: theme.palette.yellow[400],
			'&:hover': {
				backgroundColor: theme.palette.yellow[500],
			},
		},
	},
	title: {
		fontSize: 14,
		fontWeight: 600,
		margin: theme.spacing('auto', 0),
		[theme.breakpoints.up('sm')]: {
			fontSize: 16,
		},
	},
	iconswhite: {
		backgroundColor: theme.palette.green[900],
		color: theme.palette.common.white,
	},
	iconsblack: {
		backgroundColor: theme.palette.orange[400],
		color: theme.palette.common.black,
	},
	openIcon: {
		color: theme.palette.common.white,
	},
	content: {
		height: '100%',
		maxHeight: 300,
		overflow: 'hidden',
	},
	scrollContent: {
		overflowY: 'auto',
		height: '100%',
	},
	link: {
		'& > svg': {
			color: theme.palette.common.white,
		},
	},
	circularProgress: {
		'&.MuiCircularProgress-root': {
			color: theme.palette.orange[300],
		},
	},
	row: {
		paddingLeft: 15,
		paddingRight: 8,
		'&:nth-child(even)': {
			background: `linear-gradient(0deg, ${alpha(
				theme.palette.common.white,
				0.16,
			)}, ${alpha(theme.palette.common.white, 0.16)}), ${
				theme.palette.grey[1000]
			}`,
		},
	},
	symbolAmount: {
		marginLeft: theme.spacing(1),
		color: theme.palette.orange[300],
		fontWeight: 600,
	},
	symbolAmountGreen: {
		color: theme.palette.primary.main,
	},
	symbolAmountError: {
		color: theme.palette.red[700],
	},
	symbolAmountDelayed: {
		color: theme.palette.yellow[400],
	},
	transferText: {
		fontSize: 14,
		lineHeight: 2.8,
		color: theme.palette.common.white,
		marginBottom: 0,
		[theme.breakpoints.up('sm')]: {
			fontSize: 16,
		},
	},
	blockchainText: {
		fontSize: 14,
		color: alpha(theme.palette.common.white, 0.7),
		[theme.breakpoints.up('sm')]: {
			fontSize: 16,
		},
	},
	checkCircleIcon: {
		color: theme.palette.primary.main,
	},
	errorIcon: {
		color: theme.palette.red[700],
	},
	lastBox: {
		paddingRight: 12,
	},
	delayedIcon: {
		fontSize: 25,
		color: theme.palette.yellow[400],
		WebkitTransform: 'scaleX(-1)',
		transform: 'scaleX(-1)',
	},
	hide: {
		display: 'none',
	},
	show: {
		display: 'block',
	},
}));

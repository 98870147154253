import { makeStyles } from '@energi/ui';

// Header appears in front of the left sidebar on mobile mode
const ZINDEX_FRONT = 10;

export default makeStyles(theme => ({
	root: {
		display: 'flex',
		minHeight: '100vh',
		flexDirection: 'column',
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
		},
	},
	logo: {
		width: '100%',
		maxWidth: 85,
		[theme.breakpoints.up('sm')]: {
			maxWidth: 147,
			height: 58,
		},
	},
	container: {
		height: '100%',
		[theme.breakpoints.up('md')]: {
			paddingRight: 0,
		},
	},
	content: {
		flexGrow: 1,
		backgroundColor: '#18191A',
		overflowY: 'scroll',
		overflowX: 'hidden',
		position: 'relative',
		[theme.breakpoints.down('xs')]: {
			maxHeight: 809,
		},
	},
	gridRightSide: {
		height: '100vh',
		'@media(max-height: 700px)': {
			height: 'auto',
		},
	},
	rightSideContent: {
		display: 'none',
		flexDirection: 'column',
		alignItems: 'flex-start',
		height: '100%',
		marginLeft: theme.spacing(3),
		backgroundColor: theme.palette.grey[900],
		[theme.breakpoints.up('sm')]: {
			padding: theme.spacing(2),
		},
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
		[theme.breakpoints.up('lg')]: {
			padding: theme.spacing(3),
		},
	},
	appBar: {
		position: 'fixed',
		top: 0,
		left: 0,
		padding: theme.spacing(0, 3),
		width: '100%',
		zIndex: theme.zIndex.drawer + ZINDEX_FRONT,
		backgroundColor: theme.palette.grey[900],
		[theme.breakpoints.up('md')]: {
			position: 'relative',
			backgroundColor: 'transparent',
			padding: theme.spacing(0),
			zIndex: theme.zIndex.drawer,
		},
	},
	middle: {
		[theme.breakpoints.up('lg')]: {
			maxWidth: '58.333333%',
			flexBasis: '58.333333%',
		},
		[theme.breakpoints.down('md')]: {
			maxWidth: '55.333333%',
			flexBasis: '55.333333%',
		},
		[theme.breakpoints.down('sm')]: {
			maxWidth: '100%',
			flexBasis: '100%',
		},
	},
}));

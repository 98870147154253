import { makeStyles, alpha } from '@energi/ui';

export default makeStyles(theme => ({
	root: {
		width: '100%',
		borderRadius: 6,
		backgroundColor: alpha(theme.palette.common.white, 0.1),
		maxHeight: 68,
		minHeight: 68,
		marginTop: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: alpha(theme.palette.common.white, 0.18),
		},
		'&:focus': {
			backgroundColor: alpha(theme.palette.common.white, 0.2),
		},
	},
	row: {
		width: '100%',
		padding: theme.spacing(1),
	},
	txnStatus: {
		display: 'flex',
		justifyContent: 'space-between',
		paddingRight: theme.spacing(1),
		paddingLeft: theme.spacing(1),
		width: '100%',
		alignItems: 'center',
	},
	icons: {
		display: 'flex',
		alignItems: 'center',
		marginRight: 10,
		height: 22,
		'& > img': {
			width: 21,
		},
		'& > p': {
			alignSelf: 'center',
			fontSize: 14,
			fontWeight: 600,
		},
	},
	source: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		alignItems: 'start',
	},
	name: {
		fontSize: 14,
		color: 'rgba(255, 255, 255, 0.5)',
		'@media (max-width: 1440px)': {
			fontSize: 12,
		},
	},
	transactionDate: {
		fontSize: 10,
		color: 'rgba(255, 255, 255, 0.5)',
	},
	confirmationStatus: {
		fontSize: 12,
		textAlign: 'right',
	},
	confirmedStatus: {
		color: '#00E676',
	},
	pendingStatus: {
		color: '#FFB343',
	},
	delayedStatus: {
		color: '#FFE574',
	},
	destination: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'start',
		'& > .MuiTypography-body1': {
			fontSize: 12,
			letterSpacing: 0.4,
		},
	},
	amount: {
		display: 'flex',
		alignSelf: 'center',
		justifyContent: 'space-between',
		flexDirection: 'column',
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
			alignItems: 'flex-end',
			'& > .MuiTypography-body1': {
				'&:first-child': {
					paddingLeft: 0,
				},
			},
		},
	},
	bold: {
		fontWeight: 600,
		fontSize: 14,
		'@media (max-width: 1440px)': {
			fontSize: 12,
		},
	},
	arrowIcon: {
		fontSize: 18,
		margin: theme.spacing(0, 0.3),
	},
	transactionAmount: {
		fontSize: 14,
		fontWeight: 600,
		marginBottom: theme.spacing(0.6),
		'@media (max-width: 1440px)': {
			fontSize: 12,
		},
	},
	tokenTitle: {
		marginBottom: theme.spacing(0.6),
	},
	statusSection: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		'& svg': {
			fontSize: 15,
			marginLeft: 6,
			color: '#DADADA',
		},
	},
}));

import { makeStyles, alpha } from '@energi/ui';

export default makeStyles(theme => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		position: 'relative',
		background: theme.palette.grey[1003],
		borderRadius: 6,
		maxWidth: 441,
		minWidth: 441,
		boxShadow: theme.shadows[5],
		margin: theme.spacing(0, 2),
		outline: 0,
		overflowY: 'none',
		'-webkitOverflowScrolling': 'touch',
		maxHeight: '100%',
		[theme.breakpoints.down('xs')]: {
			maxWidth: '100%',
			minWidth: '100%',
			height: '100vh',
		},
		'@media(max-height: 680px)': {
			overflowY: 'scroll',
		},
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: theme.spacing(3.5, 2),
		fontSize: 20,
		color: theme.palette.white,
		borderTopLeftRadius: 6,
		borderTopRightRadius: 6,
		backgroundColor: theme.palette.grey[1005],
		[theme.breakpoints.down('sm')]: {
			fontSize: 16,
		},
	},
	title: {
		fontSize: 20,
		fontWeight: 600,
		margin: theme.spacing('auto', 0),
		display: 'flex',
		alignItems: 'center',
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(3),
		lineHeight: '24px',
	},
	summaryHeader: {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: '-2rem',
		marginTop: '-1rem',

		'& > p': {
			marginRight: theme.spacing(2),
			fontSize: 16,
			'& > span': {
				fontWeight: 600,
			},
		},
	},
	summary: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: '-2rem',
		marginTop: '-1.1rem',
		'& > p': {
			fontSize: 16,
			'&:first-child': {
				color: alpha(theme.palette.common.white, 0.7),
			},
		},
	},
	summaryFooter: {
		alignItems: 'center',
		marginBottom: theme.spacing(1),
	},
	link: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: '-1.5rem',
		marginTop: '-1.1rem',
		'& > p': {
			flex: 0.6,
			fontSize: 16,
			'&:first-child': {
				color: alpha(theme.palette.common.white, 0.7),
			},
		},
	},
	chips: {
		flex: 1,
		display: 'flex',
		justifyContent: 'flex-end',
		'& a': {
			fontSize: 12,
			lineHeight: 3,
			borderRadius: 6,
		},
	},
	destinationButton: {
		marginLeft: 10,
	},
	statusPending: {
		background: '#FFB34333',
		color: theme.palette.orange[400],
		padding: theme.spacing(1, 1, 1, 1),
		borderRadius: 6,
		fontSize: 12,
		lineHeight: 18,
	},
	statusDelayed: {
		background: 'rgba(255, 229, 116, 0.2)',
		color: theme.palette.yellow[400],
		padding: theme.spacing(1, 1, 1, 1),
		borderRadius: 6,
		fontSize: 12,
		lineHeight: 18,
	},
	statusConfirmed: {
		background: 'rgba(0, 230, 118, 0.2)',
		color: theme.palette.green[500],
		padding: theme.spacing(1),
		borderRadius: 6,
		fontSize: 12,
		lineHeight: 18,
	},
	line: {
		display: 'block',
		width: '100%',
		height: 1,
		backgroundColor: alpha(theme.palette.common.white, 0.32),
		margin: theme.spacing(3, 0),
	},
	closeButton: {
		position: 'absolute',
		top: theme.spacing(2),
		right: theme.spacing(1),
	},
	closeArrowButton: {
		height: 10,
	},
	button: {
		color: theme.palette.green[500],
		fontWeight: 600,
		fontSize: 14,
		backgroundColor: 'rgba(0, 230, 118, 0.2)',
		borderRadius: 4,
		'&:hover': {
			backgroundColor: 'rgba(0, 230, 118, 0.2)',
			boxShadow: 'none',
		},
		'& svg': {
			width: 24,
			height: 24,
		},
		[theme.breakpoints.down('sm')]: {
			width: 342,
			fontSize: 10,
		},
	},
	backdrop: {
		backgroundColor: `${alpha(theme.palette.grey[1000], 0.8)}!important`,
	},
	tooltipBox: {
		display: 'flex',
		alignItems: 'center',
		'& svg': {
			marginLeft: 12,
			cursor: 'pointer',
		},
		'& p': {
			fontSize: 16,
		},
	},
	aligned: {
		display: 'flex',
		alignItems: 'center',
		'& svg': {
			marginLeft: 10,
			fontSize: 28,
			color: 'rgba(255, 255, 255, 0.6)',
		},
	},
}));

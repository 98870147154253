import { makeStyles } from '@energi/ui';

export default makeStyles(theme => ({
	root: {
		position: 'fixed',
		top: 0,
		left: 0,
		width: '100%',
		fontSize: 12,
		height: 25,
		lineHeight: 18,
		fontWeight: 400,
		color: theme.palette.common.black,
		backgroundColor: theme.palette.orange[400],
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		zIndex: 2000,
		padding: theme.spacing(0, 1, 0, 1),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(3, 1, 3, 1),
		},
	},
	item: {
		lineHeight: 0,
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			lineHeight: 1,
		},
		'& > svg': {
			width: '1.325rem',
		},
	},
	warning: {
		marginRight: 9.75,
		verticalAlign: 'sub',
	},
}));
